/////////
// HEADER
/////////

#header {
  padding: 30px;

  #logo {
    @include image-2x($image_logo_x2, 90px, 25px);
    position: relative;
    display: inline-block;
    width: 90px;
    height: 25px;
    background: $image_logo no-repeat center center/contain;
  }

  h1 {
    display: inline-block;
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    color: $color_white;
    opacity: $opacity_5;
  }

  #userInfo {
    float: right;
    width: auto;

    li {
      position: relative;
      text-align: right;

      button {
        font-size: 16px;
        line-height: 24px;
        color: $color_white;
        text-transform: uppercase;
        opacity: $opacity_5;
        cursor: pointer;

        .downArrow {
          @include image-2x($image_down_arrow_x2, 12px, 8px);
          display: inline-block;
          margin-left: 6px;
          width: 12px;
          height: 8px;
          background: $image_down_arrow no-repeat center center/contain;
        }
      }

      ul {
        @include vendor-prefix(border-radius, $border-radius);
        position: absolute;
        right: 0;
        z-index: 100;
        color: $color_white;
        text-transform: uppercase;
        text-align: center;
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        -ms-box-sizing: content-box;
        box-sizing: content-box;

        li {
          padding: 35px 30px;
          text-align: center;

          span { // stylelint-disable-line max-nesting-depth
            font-size: 20px;
            line-height: 26px;
          }

          a { // stylelint-disable-line max-nesting-depth
            @include image-2x($image_arrow_white_x2, 20px, 13px);
            padding-right: 25px;
            color: $color_white;
            background: $image_arrow_white no-repeat right top/20px 13px;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
