//////////
// GENERIC
//////////

* {
  @include vendor-prefix(box-sizing, border-box);
  font-family: $font_family, sans-serif;
  outline: 0;
}

html {
  position: relative;
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

body {
  position: relative;
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

#root,
.container {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
}

ul {
  margin: 0;
  padding: 0;

  li {
    margin: 0;
    list-style: none;
  }
}

a {
  text-decoration: none;
}

.hidden {
  display: none;
}

.floatRight {
  float: right;
}

.floatLeft {
  float: left;
}

.clear {
  clear: both;
}

.transparentFont {
  opacity: $opacity_5;
}

.transparentLink {
  color: $color_white;
  opacity: $opacity_5;
}

.noPadding {
  padding: 0 !important; // stylelint-disable-line declaration-no-important
}

.fullWidth {
  width: 100% !important; // stylelint-disable-line declaration-no-important
}

.textRight {
  text-align: right;
}

.content {
  position: relative;
  width: 960px;
  margin: 50px auto 0;
}

/////
.colorWrapper {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;

  &.loggedOut {
    min-height: 100%;
    padding-bottom: 137px;
  }

  &.loggedIn {
    min-height: 100%;
    height: auto;
    padding-bottom: 80px;
  }
}

.loader-center {
  display: flex;
  justify-content: center;
  width: 100%;
}

.middleContainer {
  position: relative;
}

.middleBox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 300px;
  margin: 0 auto;
  text-align: center;

  h2 {
    font-size: 18px;
    line-height: 26px;
    color: $color_white;
    text-transform: uppercase;
  }

  .info {
    margin: 45px 0 0;
    font-size: 24px;
    line-height: 36px;
    color: $color_white;
    text-transform: uppercase;
  }
}

.loadingBox {
  div {
    margin: 0 auto;
  }
}

.searchBar {
  position: relative;
  float: left;
  width: 480px;
  padding-bottom: 7px;
  border-bottom: 2px solid $color_white_2;

  input.searchInput {
    position: relative;
    width: 100%;
    padding-left: 25px;
    font-size: 16px;
    line-height: 18px;
    color: $color_white;
    background: $image_search no-repeat left center/18px 18px;
  }

  ::-webkit-input-placeholder {
    color: $color_white;
    opacity: $opacity_5;
  }

  :-moz-placeholder {
    color: $color_white;
    opacity: $opacity_5;
  }

  ::-moz-placeholder {
    color: $color_white;
    opacity: $opacity_5;
  }

  :-ms-input-placeholder {
    color: $color_white;
    opacity: $opacity_5;
  }

  .cancelSearch {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 15px;
    height: 15px;
    background: $image_x_copy no-repeat center center/15px 15px;

    &.visible {
      display: block;
    }
  }
}

.simpleMsg {
  position: relative;
  float: left;
  width: 100%;
  margin-top: 30px;

  p {
    color: $color_white;
    font-size: 24px;
    text-transform: uppercase;
  }

  .asButton {
    margin-top: 45px;
  }

  button {
    margin-top: 25px;
    padding-left: 25px;
    padding-right: 25px;
  }
}

#notFoundBox {
  width: 450px;

  div {
    height: 144px;
    font-size: 180px;
    font-weight: bold;
    color: $color_white;
    background: $image_shape_940 no-repeat center center/450px 104px;
  }

  p {
    padding: 45px 0 35px;
    font-size: 18px;
    color: $color_white;
  }

  .asBtn {
    @include vendor-prefix(border-radius, $border-radius);
    @include vendor-prefix(box-shadow, $btn-box-shadow-1);
    display: inline-block;
    padding: 18px 45px 14px;
    font-size: 18px;
    text-transform: uppercase;
    background: $color_white;
    color: $color_iris_blue;
  }
}
