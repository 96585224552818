// stylelint-disable media-feature-name-no-unknown
/////////
// MIXINS
/////////

// Example usage: @include animate(left top);
@mixin animate($properties: all, $duration: $animation-speed, $easing: $easing, $delay: 0s) {

  @if $properties == transform {
    transition: transform $duration $easing $delay;
  }

  @else {
    @if length($properties) > 1 {
      $new-properties: unquote('');

      @for $i from 1 through length($properties) {
        $p: nth($properties, $i);

        @if ($i == length($properties)) {
          $new-properties: $new-properties + $p;
        }

        @else {
          $new-properties: $new-properties + $p + ', ';
        }
      }
      transition-delay: $delay;
      transition-duration: $duration;
      transition-property: $new-properties;
      transition-timing-function: $easing;
    }

    @else {
      transition: $properties $duration $easing $delay;
    }
  }
}

// e.g. @include vendor-prefix(border-radius, 5px);
@mixin vendor-prefix($name,$argument) {
  #{$name}: $argument;
  -moz-#{$name}: $argument;
  -ms-#{$name}: $argument;
  -webkit-#{$name}: $argument;
}

//Retina
@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
    (-o-min-device-pixel-ratio: 2.6/2),
    (-webkit-min-device-pixel-ratio: 1.3),
    (min-device-pixel-ratio: 1.3),
    (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: $image;
    background-size: $width $height;
  }
}
