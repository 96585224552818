///////////
// TOOLTIPS
///////////

.filterTooltip {
  @include vendor-prefix(border-radius, $border-radius);
  @include vendor-prefix(box-shadow, $btn-box-shadow-1);
  position: absolute;
  min-width: 290px;
  right: 0;
  margin-top: 20px;
  padding: 15px 10px 10px;
  background: $color_green;
  z-index: 10;

  li.hiddenImportant {
    display: none !important; // stylelint-disable-line declaration-no-important
  }

  input[type=text] {
    width: 100%;
    padding: 10px;
    background: $color_green_dark;
    color: $color_white;
    font-size: 14px;

    &::-webkit-input-placeholder {
      color: $color_white;
      opacity: $opacity_5;
    }

    &::-moz-placeholder {
      color: $color_white;
      opacity: $opacity_5;
    }

    &:-ms-input-placeholder {
      color: $color_white;
      opacity: $opacity_5;
    }
  }

  ul {
    position: relative;
    float: left;
    width: 100%;
    padding: 0 10px;

    li {
      display: block !important; // stylelint-disable-line declaration-no-important
      position: relative;
      width: 100% !important; // stylelint-disable-line declaration-no-important
      text-align: left !important; // stylelint-disable-line declaration-no-important
      padding: 10px 0;

      label {
        display: block;
        width: 100%;

        span {
          color: $color_white;
          opacity: $opacity_5;
        }
      }

      label.active {
        span {
          opacity: $opacity_10;
        }
      }

      input[type=checkbox] {
        display: none;
        float: right;
        margin-top: -4px;
      }

      input[type=radio] {
        display: none;
        float: right;
        margin-top: -4px;

        & + span {
          width: 20px;
          height: 20px;
          margin-top: -6px;
        }
      }
    }
  }

  input[type=checkbox] {
    & + span {
      float: right;
      margin-top: -3px;
      margin-right: 0;
      background-color: $color_green;
    }

    &:checked {
      & + span {
        @include image-2x($image_tick_x2, 12px, 9px);
        background-image: $image_tick;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 12px 9px;
      }
    }
  }

  input[type=radio] {
    & + span {
      float: right;
      margin-top: -3px;
      margin-right: 0;
      background-color: $color_green;
    }

    &:checked {
      & + span {
        @include image-2x($image_tick_x2, 12px, 9px);
        background-image: $image_tick;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 12px 9px;
      }
    }
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    right: 56px;
    bottom: 100%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: $color_green;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    right: 56px;
    bottom: 100%;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-bottom-color: $color_green;
  }
}

.actionTooltip {
  display: none;
  position: absolute;
  width: 172px;
  top: 30px;
  right: 0;
  padding: 20px 13px;
  background: $color_red;
  color: $color_white;
  text-align: center;
  z-index: 10;

  &.visible {
    display: block;
  }

  p {
    margin-top: 0;
    font-size: 14px;
    line-height: 20px;
    opacity: $opacity_5;
    text-transform: uppercase;
  }

  button {
    display: inline-block;
    padding: 0 7px;
    font-size: 16px;
    color: $color_white;
    text-transform: uppercase;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 134px;
    bottom: 100%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: $color_red;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 135px;
    bottom: 100%;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-bottom-color: $color_red;
  }
}

.infoTooltip {
  @include vendor-prefix(border-radius, $border-radius);
  @include vendor-prefix(box-shadow, $btn-box-shadow-1);
  display: none;
  position: absolute;
  width: 200px;
  bottom: calc(100% + 23px);
  left: 55px;
  padding: 20px 17px;
  background: $color_green;
  text-align: center;
  z-index: 10;

  &.visible {
    display: block;
  }

  p {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    color: $color_white !important; // stylelint-disable-line declaration-no-important
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 12px;
    top: 100%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: $color_green;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 12px;
    top: 100%;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-top-color: $color_green;
  }
}

.autocompleteTooltip {
  @include vendor-prefix(border-radius, $border-radius);
  @include vendor-prefix(box-shadow, $btn-box-shadow-1);
  position: absolute;
  min-width: 200px;
  margin-top: 15px;
  padding: 10px;
  background: $color_purple_light;
  z-index: 10;

  div {
    color: $color_white;
    opacity: $opacity_5;
    padding: 10px;
    cursor: pointer;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 15px;
    bottom: 100%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: $color_purple_light;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 15px;
    bottom: 100%;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-bottom-color: $color_purple_light;
  }
}
