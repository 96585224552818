///////
// ROWS
///////

.formRow {
  position: relative;
  float: left;
  width: 100%;
  margin-top: 25px;
}

.errorRow {
  position: relative;
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 12px;

  span {
    font-size: 14px;
    color: $color_white;
    text-transform: uppercase;
  }
}

.errorRow.mainFormError {
  p {
    margin: 0;
    color: $color_red;
    text-transform: uppercase;
    text-align: left;
  }
}

.successRow {
  position: relative;
  float: left;
  width: 100%;
  margin-top: 12px;

  p {
    margin: 0;
    color: $color_green !important; // stylelint-disable-line declaration-no-important
    text-transform: uppercase;
  }
}

.termsRow {
  color: $color_white;
  position: relative;
  float: left;
  width: 100%;
  font-size: 18px;
  line-height: 1.2;
  margin: 25px 0 0;

  input[type=checkbox] {
    font-size: 18px;
    left: 0;
    margin: 0;
    position: absolute;
    width: auto;
  }

  label {
    display: block;
    font-size: 18px;
    margin: 0 auto;
    padding: 20px 0 0 20px;
    position: relative;
    width: 80%;

    &::after {
      clear: both;
      content: '';
    }
  }
}

.stepRow {
  position: relative;
  float: left;
  width: 100%;
  margin-top: 18px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: $color_white;
}
