/////////
// FOOTER
/////////

#footer {
  @include image-2x($image_men_x2, 190px, 288px);
  position: absolute;
  bottom: 0;
  float: left;
  width: 100%;
  margin-bottom: 31px;
  background-image: $image_men;
  background-repeat: no-repeat;
}

#floatBanner {
  float: left;
  height: 75px;
  width: 100%;

  .float-inner-wrapper {
    @include vendor-prefix(box-shadow, $btn-box-shadow-0);
    @include animate(box-shadow);
    float: none;
    position: relative;
    z-index: 20;
  }

  &.is-download-banner,
  &.is-chart-banner {
    margin-top: -20px;
  }

  #floatBannerInner {
    background: $color_iris_blue;
    height: 75px;
    margin: 0 auto;
    padding: 20px 0;
    width: 960px;
    -webkit-backface-visibility: hidden;

    &.is-charts {
      background-color: $color_light_purple_bg;
    }

    button {
      display: inline-block;
    }

    span {
      margin-top: 10px;
      font-size: 14px;
      color: $color_white;
      text-transform: uppercase;
    }

    .downloadChecks {
      margin-top: 10px;
      font-size: 14px;
      color: $color_white;
      text-transform: uppercase;
      margin-left: 15px;
      text-decoration: underline;
      opacity: .2;

      &.active {
        opacity: $opacity_10;
      }
    }

    .deleteChecks {
      margin-top: 10px;
      font-size: 14px;
      color: $color_white;
      text-transform: uppercase;
      margin-left: 15px;
      text-decoration: underline;
      opacity: .2;

      &.active {
        opacity: $opacity_10;
      }
    }
  }

  &.floatAbove {
    .float-inner-wrapper {
      @include vendor-prefix(box-shadow, $btn-box-shadow-4);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
    }
  }
}
