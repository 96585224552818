////////
// SITES
////////

#siteHeader {
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 30px;

  .searchRow {
    position: relative;
    float: left;
    width: 100%;
    margin-top: 35px;
  }

  .actionButton {
    background: $color_purple_light;
  }

  .columnsLabels {
    position: relative;
    float: right;
    width: 360px;

    li {
      display: inline-block;
      width: 50%;
      font-size: 14px;
      color: $color_white;
      text-transform: uppercase;

      &:last-child {
        text-align: right;
      }

      select {
        color: $color_white;
        background: none;
        font-size: 14px;
        text-transform: uppercase;

        option {
          background: $color_deep_blue;
        }
      }
    }
  }
}

#siteHeader.activeHeader {
  .columnsLabels {
    li {
      &:last-child {
        width: 83px;
      }
    }
  }
}

#siteHeader.archivedHeader {
  .columnsLabels {
    li {
      &:last-child {
        width: 123px;
      }
    }
  }
}

#siteList {
  .listRow {
    border-bottom-color: $color_purple;

    td {
      color: $color_purple;
    }

    span {
      color: $color_purple;
    }

    a {
      color: $color_purple;
    }

    .asLink {
      color: $color_purple;
    }

    .wideTd {
      width: 586px;
    }

    .narrowTd {
      width: 75px;
      text-align: center;
    }
  }

  .actionTooltip {
    background-color: $color_purple_light;

    &::before {
      border-bottom-color: $color_purple_light;
    }

    &::after {
      border-bottom-color: $color_purple_light;
    }
  }
}

.siteCard {
  @include animate(opacity);
  position: relative;
  float: left;
  width: 100%;
  padding: 40px 30px;
  background: $color_white;
  opacity: 1;

  &.is-loading {
    pointer-events: none;
    opacity: .85;
  }

  h2 {
    color: $color_purple;

    input {
      font-size: 36px;
      text-transform: uppercase;
      color: $color_purple;
    }
  }

  p {
    color: $color_purple;
  }

  label {
    color: $color_purple;
  }

  input {
    color: $color_purple;
    border-color: $color_purple_5;
  }

  select {
    color: $color_purple;
    border-color: $color_purple_5;
  }

  .closeButton {
    @include image-2x($image_x_sites_x2, 23px, 23px);
    background: $image_x_sites no-repeat center center/23px 23px;
  }

  .form {
    &::-webkit-input-placeholder {
      color: $color_purple;
      opacity: $opacity_5;
    }

    &:-moz-placeholder {
      color: $color_purple;
      opacity: $opacity_5;
    }

    &::-moz-placeholder {
      color: $color_purple;
      opacity: $opacity_5;
    }

    &:-ms-input-placeholder {
      color: $color_purple;
      opacity: $opacity_5;
    }

    .formRow {
      &:nth-child(even) {
        padding-right: 20px;
        padding-left: 0;

        label {
          left: 0;
        }
      }

      &:nth-child(odd) {
        padding-left: 20px;
        padding-right: 0;
        clear: none;

        label {
          left: 20px;
        }
      }

      input[type='number'] {
        -webkit-appearance: none;

        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      input {
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      label {
        color: $color_purple;
      }
    }

    .formRow.fullWidth {
      label {
        left: 0;
      }
    }

    .btnSuccess {
      background-color: $color_purple;
      opacity: $opacity_5;
      cursor: default;
    }

    .btnSuccess.activeBtn {
      opacity: $opacity_10;
      cursor: pointer;
    }
  }

  .mapContainer {
    position: relative;
    float: left;
    width: 100%;
    margin: 20px 0;
  }

  .infoTooltip {
    &::before {
      left: 76px;
    }

    &::after {
      left: 76px;
    }
  }
}
