.chartDetails {
  width: 100%;
}

.chartContainer {
  margin: 0 0 30px;
}

.chartTitle {
  padding-bottom: 10px;
}
