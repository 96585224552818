//////////
// BUTTONS
//////////

// VARS
$btn-border-radius: $border-radius !default;

// ELEMENTS
button {
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
}

.actionButton {
  @include vendor-prefix(border-radius, $btn-border-radius);
  @include vendor-prefix(box-shadow, $btn-box-shadow-1);
  display: inline-block;
  padding: 14px 40px 11px;
  font-size: 14px;
  color: $color_white;
  text-transform: uppercase;

  &.red {
    background: $color_red !important; // stylelint-disable-line declaration-no-important
  }

  &.yellow {
    background: $color_yellow !important; // stylelint-disable-line declaration-no-important
  }
}

.closeButton {
  display: inline-block;
  width: 23px;
  height: 23px;
  margin-top: 12px;
}

.successButton {
  @include vendor-prefix(border-radius, $btn-border-radius);
  padding: 12px 20px 10px;
  background-color: $color_green;
  color: $color_white;
  text-transform: uppercase;
  opacity: $opacity_5;
  cursor: default;

  &.active {
    opacity: $opacity_10;
    cursor: pointer;
  }
}

.errorBtn {
  @include vendor-prefix(border-radius, $btn-border-radius);
  padding: 12px 20px 10px;
  background-color: $color_red;
  color: $color_white;
  text-transform: uppercase;
  opacity: $opacity_5;
  cursor: default;

  &.active {
    opacity: $opacity_10;
    cursor: pointer;
  }
}

.toggleBtn {
  @include image-2x($image_down_arrow_x2, 12px, 8px);
  padding-right: 25px;
  color: $color_white;
  font-size: 14px;
  text-transform: uppercase;
  background: $image_down_arrow no-repeat right top 1px/12px 8px;
}

.uploadBtn {
  cursor: pointer;
}

.asButton {
  @include vendor-prefix(border-radius, $btn-border-radius);
  @include vendor-prefix(box-shadow, $btn-box-shadow-1);
  display: inline-block;
  padding: 15px 65px 12px;
  font-size: 18px;
  line-height: 26px;
  text-transform: uppercase;
  color: $color_green;
  background: $color_white;
}
