////////
// USERS
////////

#userHeader {
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 30px;

  .searchRow {
    position: relative;
    float: left;
    width: 100%;
  }

  .searchBar {
    margin-top: 13px;
  }

  .actionButton {
    background: $color_user_blue;
  }

  .columnsLabels {
    position: relative;
    float: right;
    width: 383px;

    li {
      display: inline-block;
      width: 50%;
      font-size: 14px;
      color: $color_white;
      text-transform: uppercase;

      &:last-child {
        text-align: right;
      }

      select {
        color: $color_white;
        background: none;
        font-size: 14px;
        text-transform: uppercase;

        option {
          background: $color_deep_blue;
        }
      }
    }
  }

  .filterTooltip {
    min-width: 220px;
    margin-top: 30px;
  }
}

#userList {
  .listRow {
    border-bottom-color: $color_deep_blue;

    td {
      color: $color_deep_blue;
      position: relative;
      padding: 0;

      &:first-child {
        a {
          padding-left: 20px;
        }
      }

      &:last-child {
        a {
          padding-right: 20px;
        }
      }
    }

    span {
      color: $color_deep_blue;
    }

    a {
      color: $color_deep_blue;
      float: none;
      display: inline-block;
      width: 100%;
      text-decoration: none;
      padding: 17px 0 14px;
    }

    .asLink {
      color: $color_deep_blue;
      margin: 0;
      padding: 17px 20px 14px 30px;
    }

    .wideTd {
      width: 586px;
    }

    .narrowTd {
      width: 75px;
      text-align: left;
    }

    .actionTooltip {
      top: 50px;
      right: 20px;
    }
  }
}

.userCard {
  position: relative;
  float: left;
  width: 100%;
  padding: 40px 30px;
  background: $color_white;

  h2 {
    color: $color_deep_blue;
  }

  p {
    color: $color_deep_blue;
    max-width: 862px;
  }

  label {
    color: $color_deep_blue;
  }

  input {
    color: $color_deep_blue;
    border-color: $color_deep_blue_5;
  }

  select {
    color: $color_deep_blue;
    border-color: $color_deep_blue_5;
  }

  .closeButton {
    @include image-2x($image_x_user_x2, 23px, 23px);
    background: $image_x_user no-repeat center center/23px 23px;
  }

  .form {
    &::-webkit-input-placeholder {
      color: $color_deep_blue;
      opacity: $opacity_5;
    }

    &:-moz-placeholder {
      color: $color_deep_blue;
      opacity: $opacity_5;
    }

    &::-moz-placeholder {
      color: $color_deep_blue;
      opacity: $opacity_5;
    }

    &:-ms-input-placeholder {
      color: $color_deep_blue;
      opacity: $opacity_5;
    }

    .btnInfo {
      color: $color_deep_blue;
      opacity: $opacity_5;
    }
  }
}

#userForm {
  .toggleBtn {
    @include image-2x($image_drop_down_arrow_user_x2, 12px, 8px);
    float: left;
    width: 100%;
    padding-bottom: 7px;
    font-size: 18px;
    color: $color_deep_blue;
    text-align: left;
    text-transform: none;
    background: $image_drop_down_arrow_user no-repeat right top 1px/12px 8px;
    border-bottom: 2px solid $color_deep_blue_5;
  }

  .filterTooltip {
    width: calc(100% - 20px);
    top: 40px;
    padding: 10px;

    &::before {
      right: 0;
      left: 15px;
    }

    &::after {
      right: 0;
      left: 15px;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        padding: 10px;
      }
    }

    label {
      @include vendor-prefix(transform, none);
      @include vendor-prefix(transition, none);
      position: relative;
      width: 100%;
      left: 0;
      top: 0;
    }
  }
}
