//////////
// COLOURS
//////////

.greenBg {
  background-color: $color_green;

  .twoInputsForm {
    .buttonRow {
      button {
        color: $color_green;
      }
    }
  }
}

.blueBg {
  background-color: $color_blue;

  .twoInputsForm {
    .buttonRow {
      button {
        color: $color_blue;
      }
    }
  }
}

.blueLightBg {
  background-color: $color_iris_blue;
}

.blueDarkBg {
  background-color: $color_deep_blue;
}

.purpleLightBg {
  background-color: $color_light_purple_bg;
}

.purpleBg {
  background-color: $color_purple;
}

.redBg {
  background-color: $color_red;
}

.successBg {
  background-color: $color_green_success;
}

.greenDarkBg {
  background-color: $color_green_dark;
}

.bgAnimate {
  transition: all .2s ease-in-out;
}
