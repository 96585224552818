/////////////
// TYPOGRAPHY
/////////////

// @font-face {
// 	font-family: $font_family;
// 	font-weight: Normal;
// 	font-style: Normal;
// src: url($font_0);
// src: url($font_1) format("embedded-opentype"),
// 		 url($font_2) format("woff2"),
// 		 url($font_3) format("woff");
// }

h1 {
  text-transform: uppercase;
}

h2 {
  text-transform: uppercase;
  margin: 0;
  font-size: 36px;
  line-height: 54px;
}

h3 {
  text-transform: uppercase;
  margin: 0;
}

h4 {
  text-transform: uppercase;
}

p {
  font-size: 16px;
  line-height: 24px;
}
