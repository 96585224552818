////////////////
// MEDIA QUERIES
////////////////

@media (min--moz-device-pixel-ratio: 1.3) { // stylelint-disable-line media-feature-name-no-unknown
  // #header {
  // 	#logo {
  // 		background-image: $image_logo_x2;
  // 		background-size: 90px 25px;
  //   }

  // 	#userInfo {
  // 		li {
  // 			button {
  // 				.downArrow {
  // 					background-image: $image_down_arrow_x2;
  // 					background-size: 12px 8px;
  // 				}
  //       }

  // 			ul {
  // 				li {
  // 					a {
  // 						background-image: $image_arrow_white_x2;
  // 						background-size: 20px 13px;
  // 					}
  // 				}
  // 			}
  // 		}
  // 	}
  // }

  // #footer {
  // 	background-image: $image_men_x2;
  //   background-size: 190px 288px;

  // 	#zaangLogo {
  // 		background-image: $image_zaang_x2;
  // 		background-size: 60px 20px;
  // 	}
  // }

  // .form {
  // 	.infoIcon {
  // 		background-image: $image_info_x2;
  // 		background-size: 11px 11px;
  // 	}
  // }

  // .list {
  // 	table {
  // 		.listRow {
  // 			.pic {
  // 				background-image: $image_attached_image_x2;
  // 				background-size: 20px 18px;
  //       }

  // 			.check {
  // 				background-image: $image_big_green_tick_x2;
  // 				background-size: 19px 15px;
  // 			}
  // 		}
  // 	}
  // }

  // .listNav {
  // 	a.prev {
  // 		background-image: $image_prev_x2;
  // 		background-size: 8px 12px;
  //   }

  // 	a.next {
  // 		background-image: $image_next_x2;
  // 		background-size: 8px 12px;
  // 	}
  // }

  // .searchBar {
  // 	input.searchInput {
  // 		background-image: $image_search_x2;
  // 		background-size: 18px 18px;
  //   }

  // 	.cancelSearch {
  // 		background-image: $image_x_copy_x2;
  // 		background-size: 15px 15px;
  // 	}
  // }

  .toggleBtn {
    // background-image: $image_down_arrow_x2;
    // background-size: 12px 8px;
    background-position-y: 4px;
  }

  // .asCheckbox {
  // 	background-image: $image_tick_x2;
  // 	background-size: 12px 9px;
  // }

  // .filterTooltip {
  // 	input[type="checkbox"] {
  // 		&:checked {
  // 			&+span {
  // 				background-image: $image_tick_x2;
  // 				background-size: 12px 9px;
  // 			}
  // 		}
  //   }

  // 	input[type="radio"] {
  // 		&:checked {
  // 			&+span {
  // 				background-image: $image_tick_x2;
  // 				background-size: 12px 9px;
  // 			}
  // 		}
  // 	}
  // }

  // #dateRangeList {
  // 	.selectButton {
  // 		background-image: $image_down_arrow_x2;
  // 		background-size: 12px 8px;
  //   }

  // 	ul {
  // 		li {
  // 			button.active {
  // 				background-image: $image_tick_x2;
  // 				background-size: 14px 10px;
  //       }

  // 			button.datePickerButton {
  // 				background-image: $image_date_icon_x2;
  // 				background-size: 15px 14px;
  // 			}
  // 		}
  // 	}
  // }

  // .datepicker {
  // 	.doneButton {
  // 		button {
  // 			background-image: $image_tick_x2;
  // 			background-size: 14px 10px;
  // 		}
  // 	}
  // }

  // .userCard {
  // 	.closeButton {
  // 		background-image: $image_x_user_x2;
  // 		background-size: 23px 23px;
  // 	}
  // }

  // #userForm {
  // 	.toggleBtn {
  // 		background-image: $image_drop_down_arrow_user_x2;
  // 		background-size: 12px 8px;
  // 	}
  // }

  // #reportList {
  // 	.viewTd {
  // 		.asLink {
  // 			background-image: $image_arrow_blue_x2;
  // 			background-size: 20px 14px;
  // 		}
  // 	}
  // }

  // .reportDetail {
  // 	.closeButton {
  // 		background-image: $image_x_x2;
  // 		background-size: 23px 23px;
  // 	}
  // }

  // .reportDetail.reportDownload {
  // 	input[type=checkbox] {
  // 		&:checked {
  // 			&+span {
  // 				background-image: $image_tick_blue_x2;
  // 				background-size: 12px 9px;
  // 			}
  // 		}
  //   }

  // 	.downloadHeader {
  // 		.searchRow {
  // 			input.searchInput {
  // 				background-image: $image_search_report_x2;
  // 				background-size: 18px 18px;
  //       }

  // 			.cancelSearch {
  // 				background-image: $image_x_x2;
  // 				background-size: 15px 15px;
  // 			}
  //     }

  // 		.columnsLabels {
  // 			li {
  // 				.toggleBtn {
  // 					background-image: $image_down_arrow_reports_x2;
  // 					background-size: 12px 8px;
  // 				}
  //       }

  // 			.filterTooltip {
  // 				input[type="checkbox"] {
  // 					&:checked {
  // 						&+span {
  // 							background-image: $image_tick_x2;
  // 							background-size: 12px 9px;
  // 						}
  // 					}
  //         }

  // 				input[type="radio"] {
  // 					&:checked {
  // 						&+span {
  // 							background-image: $image_tick_x2;
  // 							background-size: 12px 9px;
  // 						}
  // 					}
  // 				}
  // 			}
  // 		}
  // 	}
  // }

  // .reportCheckboxRow {
  // 	input[type=checkbox] {
  // 		&:checked {
  // 			&+span {
  // 				background-image: $image_tick_blue_x2;
  // 				background-size: 12px 9px;
  // 			}
  // 		}
  // 	}
  // }

  // .siteCard {
  // 	.closeButton {
  // 		background-image: $image_x_sites_x2;
  // 		background-size: 23px 23px;
  // 	}
  // }

  // #notFoundBox {
  // 	div {
  // 		background-image: $image_shape_940_x2;
  // 		background-size: 450px 104px;
  // 	}
  // }

  // .pagination-btn.is-first {
  // 	background-image: $image_first_white_x2;
  // 	background-size: 12px 12px;
  // }

  // .pagination-btn.is-last {
  // 	background-image: $image_last_white_x2;
  // 	background-size: 12px 12px;
  // }

  // .pagination-btn.is-prev {
  // 	background-image: $image_prev_x2;
  // 	background-size: 8px 12px;
  // }

  // .pagination-btn.is-next {
  // 	background-image: $image_next_x2;
  // 	background-size: 8px 12px;
  // }

  // .pageSizeLabel.active {
  // 	span {
  // 		background-image: $image_tick_x2;
  // 		background-size: 12px 9px;
  // 	}
  // }
}

@media (-o-min-device-pixel-ratio: 2.6 / 2) {
  // #header {
  // 	#logo {
  // 		background-image: $image_logo_x2;
  // 		background-size: 90px 25px;
  //   }

  // 	#userInfo {
  // 		li {
  // 			button {
  // 				.downArrow {
  // 					background-image: $image_down_arrow_x2;
  // 					background-size: 12px 8px;
  // 				}
  //       }

  // 			ul {
  // 				li {
  // 					a {
  // 						background-image: $image_arrow_white_x2;
  // 						background-size: 20px 13px;
  // 					}
  // 				}
  // 			}
  // 		}
  // 	}
  // }

  // #footer {
  // 	background-image: $image_men_x2;
  //   background-size: 190px 288px;

  // 	#zaangLogo {
  // 		background-image: $image_zaang_x2;
  // 		background-size: 60px 20px;
  // 	}
  // }

  // .form {
  // 	.infoIcon {
  // 		background-image: $image_info_x2;
  // 		background-size: 11px 11px;
  // 	}
  // }

  // .list {
  // 	table {
  // 		.listRow {
  // 			.pic {
  // 				background-image: $image_attached_image_x2;
  // 				background-size: 20px 18px;
  //       }

  // 			.check {
  // 				background-image: $image_big_green_tick_x2;
  // 				background-size: 19px 15px;
  // 			}
  // 		}
  // 	}
  // }

  // .listNav {
  // 	a.prev {
  // 		background-image: $image_prev_x2;
  // 		background-size: 8px 12px;
  // 	}
  // 	a.next {
  // 		background-image: $image_next_x2;
  // 		background-size: 8px 12px;
  // 	}
  // }

  // .searchBar {
  // 	input.searchInput {
  // 		background-image: $image_search_x2;
  // 		background-size: 18px 18px;
  //   }

  // 	.cancelSearch {
  // 		background-image: $image_x_copy_x2;
  // 		background-size: 15px 15px;
  // 	}
  // }

  .toggleBtn {
    // background-image: $image_down_arrow_x2;
    // background-size: 12px 8px;
    background-position-y: 4px;
  }

  // .asCheckbox {
  // 	background-image: $image_tick_x2;
  // 	background-size: 12px 9px;
  // }
  // .filterTooltip {
  // 	input[type="checkbox"] {
  // 		&:checked {
  // 			&+span {
  // 				background-image: $image_tick_x2;
  // 				background-size: 12px 9px;
  // 			}
  // 		}
  // 	}
  // 	input[type="radio"] {
  // 		&:checked {
  // 			&+span {
  // 				background-image: $image_tick_x2;
  // 				background-size: 12px 9px;
  // 			}
  // 		}
  // 	}
  // }
  // #dateRangeList {
  // 	.selectButton {
  // 		background-image: $image_down_arrow_x2;
  // 		background-size: 12px 8px;
  // 	}
  // 	ul {
  // 		li {
  // 			button.active {
  // 				background-image: $image_tick_x2;
  // 				background-size: 14px 10px;
  // 			}
  // 			button.datePickerButton {
  // 				background-image: $image_date_icon_x2;
  // 				background-size: 15px 14px;
  // 			}
  // 		}
  // 	}
  // }
  // .datepicker {
  // 	.doneButton {
  // 		button {
  // 			background-image: $image_tick_x2;
  // 			background-size: 14px 10px;
  // 		}
  // 	}
  // }
  // .userCard {
  // 	.closeButton {
  // 		background-image: $image_x_user_x2;
  // 		background-size: 23px 23px;
  // 	}
  // }
  // #userForm {
  // 	.toggleBtn {
  // 		background-image: $image_drop_down_arrow_user_x2;
  // 		background-size: 12px 8px;
  // 	}
  // }
  // #reportList {
  // 	.viewTd {
  // 		.asLink {
  // 			background-image: $image_arrow_blue_x2;
  // 			background-size: 20px 14px;
  // 		}
  // 	}
  // }
  // .reportDetail {
  // 	.closeButton {
  // 		background-image: $image_x_x2;
  // 		background-size: 23px 23px;
  // 	}
  // }
  // .reportDetail.reportDownload {
  // 	input[type=checkbox] {
  // 		&:checked {
  // 			&+span {
  // 				background-image: $image_tick_blue_x2;
  // 				background-size: 12px 9px;
  // 			}
  // 		}
  // 	}
  // .downloadHeader {
  // 	.searchRow {
  // 		input.searchInput {
  // 			background-image: $image_search_report_x2;
  // 			background-size: 18px 18px;
  // 		}
  // 		.cancelSearch {
  // 			background-image: $image_x_x2;
  // 			background-size: 15px 15px;
  // 		}
  // 	}
  // 	.columnsLabels {
  // 		li {
  // 			.toggleBtn {
  // 				background-image: $image_down_arrow_reports_x2;
  // 				background-size: 12px 8px;
  // 			}
  // 		}
  // 		.filterTooltip {
  // 			input[type="checkbox"] {
  // 				&:checked {
  // 					&+span {
  // 						background-image: $image_tick_x2;
  // 						background-size: 12px 9px;
  // 					}
  // 				}
  // 			}
  // 			input[type="radio"] {
  // 				&:checked {
  // 					&+span {
  // 						background-image: $image_tick_x2;
  // 						background-size: 12px 9px;
  // 					}
  // 				}
  // 			}
  // 		}
  // 	}
  // }
}

// .reportCheckboxRow {
// 	input[type=checkbox] {
// 		&:checked {
// 			&+span {
// 				background-image: $image_tick_blue_x2;
// 				background-size: 12px 9px;
// 			}
// 		}
// 	}
// }
// .siteCard {
// 	.closeButton {
// 		background-image: $image_x_sites_x2;
// 		background-size: 23px 23px;
// 	}
// }
// #notFoundBox {
// 	div {
// 		background-image: $image_shape_940_x2;
// 		background-size: 450px 104px;
// 	}
// }
// .pagination-btn.is-first {
// 	background-image: $image_first_white_x2;
// 	background-size: 12px 12px;
// }
// .pagination-btn.is-last {
// 	background-image: $image_last_white_x2;
// 	background-size: 12px 12px;
// }
// .pagination-btn.is-prev {
// 	background-image: $image_prev_x2;
// 	background-size: 8px 12px;
// }
// 	.pagination-btn.is-next {
// 		background-image: $image_next_x2;
// 		background-size: 8px 12px;
// 	}
// 	.pageSizeLabel.active {
// 		span {
// 			background-image: $image_tick_x2;
// 			background-size: 12px 9px;
// 		}
// 	}
// }

@media (-webkit-min-device-pixel-ratio: 1.3) {

  // #header {
  // 	#logo {
  // 		background-image: $image_logo_x2;
  // 		background-size: 90px 25px;
  // 	}
  // 	#userInfo {
  // 		li {
  // 			button {
  // 				.downArrow {
  // 					background-image: $image_down_arrow_x2;
  // 					background-size: 12px 8px;
  // 				}
  // 			}
  // 			ul {
  // 				li {
  // 					a {
  // 						background-image: $image_arrow_white_x2;
  // 						background-size: 20px 13px;
  // 					}
  // 				}
  // 			}
  // 		}
  // 	}
  // }
  // #footer {
  // 	background-image: $image_men_x2;
  // 	background-size: 190px 288px;
  // 	#zaangLogo {
  // 		background-image: $image_zaang_x2;
  // 		background-size: 60px 20px;
  // 	}
  // }
  // .form {
  // 	.infoIcon {
  // 		background-image: $image_info_x2;
  // 		background-size: 11px 11px;
  // 	}
  // }
  // .list {
  // 	table {
  // 		.listRow {
  // 			.pic {
  // 				background-image: $image_attached_image_x2;
  // 				background-size: 20px 18px;
  // 			}
  // 			.check {
  // 				background-image: $image_big_green_tick_x2;
  // 				background-size: 19px 15px;
  // 			}
  // 		}
  // 	}
  // }
  // .listNav {
  // 	a.prev {
  // 		background-image: $image_prev_x2;
  // 		background-size: 8px 12px;
  // 	}
  // 	a.next {
  // 		background-image: $image_next_x2;
  // 		background-size: 8px 12px;
  // 	}
  // }
  // .searchBar {
  // 	input.searchInput {
  // 		background-image: $image_search_x2;
  // 		background-size: 18px 18px;
  // 	}
  // 	.cancelSearch {
  // 		background-image: $image_x_copy_x2;
  // 		background-size: 15px 15px;
  // 	}
  // }
  .toggleBtn {
    // background-image: $image_down_arrow_x2;
    // background-size: 12px 8px;
    background-position-y: 4px;
  }

  // .asCheckbox {
  // 	background-image: $image_tick_x2;
  // 	background-size: 12px 9px;
  // }
  // .filterTooltip {
  // 	input[type="checkbox"] {
  // 		&:checked {
  // 			&+span {
  // 				background-image: $image_tick_x2;
  // 				background-size: 12px 9px;
  // 			}
  // 		}
  // 	}
  // 	input[type="radio"] {
  // 		&:checked {
  // 			&+span {
  // 				background-image: $image_tick_x2;
  // 				background-size: 12px 9px;
  // 			}
  // 		}
  // 	}
  // }
  // #dateRangeList {
  // 	.selectButton {
  // 		background-image: $image_down_arrow_x2;
  // 		background-size: 12px 8px;
  // 	}
  // 	ul {
  // 		li {
  // 			button.active {
  // 				background-image: $image_tick_x2;
  // 				background-size: 14px 10px;
  // 			}
  // 			button.datePickerButton {
  // 				background-image: $image_date_icon_x2;
  // 				background-size: 15px 14px;
  // 			}
  // 		}
  // 	}
  // }
  // .datepicker {
  // 	.doneButton {
  // 		button {
  // 			background-image: $image_tick_x2;
  // 			background-size: 14px 10px;
  // 		}
  // 	}
  // }
  // .userCard {
  // 	.closeButton {
  // 		background-image: $image_x_user_x2;
  // 		background-size: 23px 23px;
  // 	}
  // }
  // #userForm {
  // 	.toggleBtn {
  // 		background-image: $image_drop_down_arrow_user_x2;
  // 		background-size: 12px 8px;
  // 	}
  // }
  // #reportList {
  // 	.viewTd {
  // 		.asLink {
  // 			background-image: $image_arrow_blue_x2;
  // 			background-size: 20px 14px;
  // 		}
  // 	}
  // }
  // .reportDetail {
  // 	.closeButton {
  // 		background-image: $image_x_x2;
  // 		background-size: 23px 23px;
  // 	}
  // }
  // .reportDetail.reportDownload {
  // 	input[type=checkbox] {
  // 		&:checked {
  // 			&+span {
  // 				background-image: $image_tick_blue_x2;
  // 				background-size: 12px 9px;
  // 			}
  // 		}
  // 	}
  // 	.downloadHeader {
  // 		.searchRow {
  // 			input.searchInput {
  // 				background-image: $image_search_report_x2;
  // 				background-size: 18px 18px;
  // 			}
  // 			.cancelSearch {
  // 				background-image: $image_x_x2;
  // 				background-size: 15px 15px;
  // 			}
  // 		}
  // 		.columnsLabels {
  // 			li {
  // 				.toggleBtn {
  // 					background-image: $image_down_arrow_reports_x2;
  // 					background-size: 12px 8px;
  // 				}
  // 			}
  // 			.filterTooltip {
  // 				input[type="checkbox"] {
  // 					&:checked {
  // 						&+span {
  // 							background-image: $image_tick_x2;
  // 							background-size: 12px 9px;
  // 						}
  // 					}
  // 				}
  // 				input[type="radio"] {
  // 					&:checked {
  // 						&+span {
  // 							background-image: $image_tick_x2;
  // 							background-size: 12px 9px;
  // 						}
  // 					}
  // 				}
  // 			}
  // 		}
  // 	}
  // }
  // .reportCheckboxRow {
  // 	input[type=checkbox] {
  // 		&:checked {
  // 			&+span {
  // 				background-image: $image_tick_blue_x2;
  // 				background-size: 12px 9px;
  // 			}
  // 		}
  // 	}
  // }
  // .siteCard {
  // 	.closeButton {
  // 		background-image: $image_x_sites_x2;
  // 		background-size: 23px 23px;
  // 	}
  // }
  // #notFoundBox {
  // 	div {
  // 		background-image: $image_shape_940_x2;
  // 		background-size: 450px 104px;
  // 	}
  // }
  // .pagination-btn.is-first {
  // 	background-image: $image_first_white_x2;
  // 	background-size: 12px 12px;
  // }
  // .pagination-btn.is-last {
  // 	background-image: $image_last_white_x2;
  // 	background-size: 12px 12px;
  // }
  // .pagination-btn.is-prev {
  // 	background-image: $image_prev_x2;
  // 	background-size: 8px 12px;
  // }
  // .pagination-btn.is-next {
  // 	background-image: $image_next_x2;
  // 	background-size: 8px 12px;
  // }
  // .pageSizeLabel.active {
  // 	span {
  // 		background-image: $image_tick_x2;
  // 		background-size: 12px 9px;
  // 	}
  // }
}

@media (min-device-pixel-ratio: 1.3) { // stylelint-disable-line media-feature-name-no-unknown

  // #header {
  // 	#logo {
  // 		background-image: $image_logo_x2;
  // 		background-size: 90px 25px;
  // 	}
  // 	#userInfo {
  // 		li {
  // 			button {
  // 				.downArrow {
  // 					background-image: $image_down_arrow_x2;
  // 					background-size: 12px 8px;
  // 				}
  // 			}
  // 			ul {
  // 				li {
  // 					a {
  // 						background-image: $image_arrow_white_x2;
  // 						background-size: 20px 13px;
  // 					}
  // 				}
  // 			}
  // 		}
  // 	}
  // }
  // #footer {
  // 	background-image: $image_men_x2;
  // 	background-size: 190px 288px;
  // 	#zaangLogo {
  // 		background-image: $image_zaang_x2;
  // 		background-size: 60px 20px;
  // 	}
  // }
  // .form {
  // 	.infoIcon {
  // 		background-image: $image_info_x2;
  // 		background-size: 11px 11px;
  // 	}
  // }
  // .list {
  // 	table {
  // 		.listRow {
  // 			.pic {
  // 				background-image: $image_attached_image_x2;
  // 				background-size: 20px 18px;
  // 			}
  // 			.check {
  // 				background-image: $image_big_green_tick_x2;
  // 				background-size: 19px 15px;
  // 			}
  // 		}
  // 	}
  // }
  // .listNav {
  // 	a.prev {
  // 		background-image: $image_prev_x2;
  // 		background-size: 8px 12px;
  // 	}
  // 	a.next {
  // 		background-image: $image_next_x2;
  // 		background-size: 8px 12px;
  // 	}
  // }
  // .searchBar {
  // 	input.searchInput {
  // 		background-image: $image_search_x2;
  // 		background-size: 18px 18px;
  // 	}
  // 	.cancelSearch {
  // 		background-image: $image_x_copy_x2;
  // 		background-size: 15px 15px;
  // 	}
  // }
  .toggleBtn {
    // background-image: $image_down_arrow_x2;
    // background-size: 12px 8px;
    background-position-y: 4px;
  }

  // .asCheckbox {
  // 	background-image: $image_tick_x2;
  // 	background-size: 12px 9px;
  // }
  // .filterTooltip {
  // 	input[type="checkbox"] {
  // 		&:checked {
  // 			&+span {
  // 				background-image: $image_tick_x2;
  // 				background-size: 12px 9px;
  // 			}
  // 		}
  // 	}
  // 	input[type="radio"] {
  // 		&:checked {
  // 			&+span {
  // 				background-image: $image_tick_x2;
  // 				background-size: 12px 9px;
  // 			}
  // 		}
  // 	}
  // }
  // #dateRangeList {
  // 	.selectButton {
  // 		background-image: $image_down_arrow_x2;
  // 		background-size: 12px 8px;
  // 	}
  // 	ul {
  // 		li {
  // 			button.active {
  // 				background-image: $image_tick_x2;
  // 				background-size: 14px 10px;
  // 			}
  // 			button.datePickerButton {
  // 				background-image: $image_date_icon_x2;
  // 				background-size: 15px 14px;
  // 			}
  // 		}
  // 	}
  // }
  // .datepicker {
  // 	.doneButton {
  // 		button {
  // 			background-image: $image_tick_x2;
  // 			background-size: 14px 10px;
  // 		}
  // 	}
  // }
  // .userCard {
  // 	.closeButton {
  // 		background-image: $image_x_user_x2;
  // 		background-size: 23px 23px;
  // 	}
  // }
  // #userForm {
  // 	.toggleBtn {
  // 		background-image: $image_drop_down_arrow_user_x2;
  // 		background-size: 12px 8px;
  // 	}
  // }
  // #reportList {
  // 	.viewTd {
  // 		.asLink {
  // 			background-image: $image_arrow_blue_x2;
  // 			background-size: 20px 14px;
  // 		}
  // 	}
  // }
  // .reportDetail {
  // 	.closeButton {
  // 		background-image: $image_x_x2;
  // 		background-size: 23px 23px;
  // 	}
  // }
  // .reportDetail.reportDownload {
  // 	input[type=checkbox] {
  // 		&:checked {
  // 			&+span {
  // 				background-image: $image_tick_blue_x2;
  // 				background-size: 12px 9px;
  // 			}
  // 		}
  // 	}
  // 	.downloadHeader {
  // 		.searchRow {
  // 			input.searchInput {
  // 				background-image: $image_search_report_x2;
  // 				background-size: 18px 18px;
  // 			}
  // 			.cancelSearch {
  // 				background-image: $image_x_x2;
  // 				background-size: 15px 15px;
  // 			}
  // 		}
  // 		.columnsLabels {
  // 			li {
  // 				.toggleBtn {
  // 					background-image: $image_down_arrow_reports_x2;
  // 					background-size: 12px 8px;
  // 				}
  // 			}
  // 			.filterTooltip {
  // 				input[type="checkbox"] {
  // 					&:checked {
  // 						&+span {
  // 							background-image: $image_tick_x2;
  // 							background-size: 12px 9px;
  // 						}
  // 					}
  // 				}
  // 				input[type="radio"] {
  // 					&:checked {
  // 						&+span {
  // 							background-image: $image_tick_x2;
  // 							background-size: 12px 9px;
  // 						}
  // 					}
  // 				}
  // 			}
  // 		}
  // 	}
  // }
  // .reportCheckboxRow {
  // 	input[type=checkbox] {
  // 		&:checked {
  // 			&+span {
  // 				background-image: $image_tick_blue_x2;
  // 				background-size: 12px 9px;
  // 			}
  // 		}
  // 	}
  // }
  // .siteCard {
  // 	.closeButton {
  // 		background-image: $image_x_sites_x2;
  // 		background-size: 23px 23px;
  // 	}
  // }
  // #notFoundBox {
  // 	div {
  // 		background-image: $image_shape_940_x2;
  // 		background-size: 450px 104px;
  // 	}
  // }
  // .pagination-btn.is-first {
  // 	background-image: $image_first_white_x2;
  // 	background-size: 12px 12px;
  // }
  // .pagination-btn.is-last {
  // 	background-image: $image_last_white_x2;
  // 	background-size: 12px 12px;
  // }
  // .pagination-btn.is-prev {
  // 	background-image: $image_prev_x2;
  // 	background-size: 8px 12px;
  // }
  // .pagination-btn.is-next {
  // 	background-image: $image_next_x2;
  // 	background-size: 8px 12px;
  // }
  // .pageSizeLabel.active {
  // 	span {
  // 		background-image: $image_tick_x2;
  // 		background-size: 12px 9px;
  // 	}
  // }
}

@media (min-resolution: 1.3dppx) {
  #header {
    #logo {
      background-image: $image_logo_x2;
      background-size: 90px 25px;
    }

    #userInfo {
      li {
        button {
          .downArrow {
            background-image: $image_down_arrow_x2;
            background-size: 12px 8px;
          }
        }

        ul {
          li {
            a { // stylelint-disable-line max-nesting-depth
              background-image: $image_arrow_white_x2;
              background-size: 20px 13px;
            }
          }
        }
      }
    }
  }

  #footer {
    background-image: $image_men_x2;
    background-size: 190px 288px;

    #zaangLogo {
      background-image: $image_zaang_x2;
      background-size: 60px 20px;
    }
  }

  .form {
    .infoIcon {
      background-image: $image_info_x2;
      background-size: 11px 11px;
    }
  }

  .list {
    table {
      .listRow {
        .pic {
          background-image: $image_attached_image_x2;
          background-size: 20px 18px;
        }

        .check {
          background-image: $image_big_green_tick_x2;
          background-size: 19px 15px;
        }
      }
    }
  }

  .listNav {
    a.prev {
      background-image: $image_prev_x2;
      background-size: 8px 12px;
    }

    a.next {
      background-image: $image_next_x2;
      background-size: 8px 12px;
    }
  }

  .searchBar {
    input.searchInput {
      background-image: $image_search_x2;
      background-size: 18px 18px;
    }

    .cancelSearch {
      background-image: $image_x_copy_x2;
      background-size: 15px 15px;
    }
  }

  .toggleBtn {
    background-image: $image_down_arrow_x2;
    background-size: 12px 8px;
    background-position-y: 4px;
  }

  .asCheckbox {
    background-image: $image_tick_x2;
    background-size: 12px 9px;
  }

  .filterTooltip {
    input[type="checkbox"] {
      &:checked {
        & + span {
          background-image: $image_tick_x2;
          background-size: 12px 9px;
        }
      }
    }

    input[type="radio"] {
      &:checked {
        & + span {
          background-image: $image_tick_x2;
          background-size: 12px 9px;
        }
      }
    }
  }

  #dateRangeList {
    .selectButton {
      background-image: $image_down_arrow_x2;
      background-size: 12px 8px;
    }

    ul {
      li {
        button.active {
          background-image: $image_tick_x2;
          background-size: 14px 10px;
        }

        button.datePickerButton {
          background-image: $image_date_icon_x2;
          background-size: 15px 14px;
        }
      }
    }
  }

  .datepicker {
    .doneButton {
      button {
        background-image: $image_tick_x2;
        background-size: 14px 10px;
      }
    }
  }

  .userCard {
    .closeButton {
      background-image: $image_x_user_x2;
      background-size: 23px 23px;
    }
  }

  // #userForm {
  // 	.toggleBtn {
  // 		background-image: $image_drop_down_arrow_user_x2;
  // 		background-size: 12px 8px;
  // 	}
  // }
  #reportList {
    .viewTd {
      .asLink {
        background-image: $image_arrow_blue_x2;
        background-size: 20px 14px;
      }
    }
  }

  .reportDetail {
    .closeButton {
      background-image: $image_x_x2;
      background-size: 23px 23px;
    }
  }

  .reportDetail.reportDownload {
    input[type=checkbox] {
      &:checked {
        & + span {
          background-image: $image_tick_x2;
          background-size: 12px 9px;
        }
      }
    }

    .downloadHeader {
      .searchRow {
        input.searchInput {
          background-image: $image_search_report_x2;
          background-size: 18px 18px;
        }

        .cancelSearch {
          background-image: $image_x_x2;
          background-size: 15px 15px;
        }
      }

      .columnsLabels {
        li {
          .toggleBtn {
            background-image: $image_down_arrow_reports_x2;
            background-size: 12px 8px;
          }
        }

        .filterTooltip {
          input[type="checkbox"] {
            &:checked { // stylelint-disable-line max-nesting-depth
              & + span { // stylelint-disable-line max-nesting-depth
                background-image: $image_tick_x2;
                background-size: 12px 9px;
              }
            }
          }

          input[type="radio"] {
            &:checked { // stylelint-disable-line max-nesting-depth
              & + span { // stylelint-disable-line max-nesting-depth
                background-image: $image_tick_x2;
                background-size: 12px 9px;
              }
            }
          }
        }
      }
    }
  }

  .reportCheckboxRow {
    input[type=checkbox] {
      &:checked {
        & + span {
          background-image: $image_tick_blue_x2;
          background-size: 12px 9px;
        }
      }
    }
  }

  .siteCard {
    .closeButton {
      background-image: $image_x_sites_x2;
      background-size: 23px 23px;
    }
  }

  #notFoundBox {
    div {
      background-image: $image_shape_940_x2;
      background-size: 450px 104px;
    }
  }

  .pagination-btn.is-first {
    background-image: $image_first_white_x2;
    background-size: 12px 12px;
  }

  .pagination-btn.is-last {
    background-image: $image_last_white_x2;
    background-size: 12px 12px;
  }

  .pagination-btn.is-prev {
    background-image: $image_prev_x2;
    background-size: 8px 12px;
  }

  .pagination-btn.is-next {
    background-image: $image_next_x2;
    background-size: 8px 12px;
  }

  .pageSizeLabel.active {
    span {
      background-image: $image_tick_x2;
      background-size: 12px 9px;
    }
  }
}
