///////////
// UPLOADER
///////////

.hiddenUpload {
  width: .1px;
  height: .1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.uploadLoader {
  display: none;
  background-color: $color_white;
  color: $color_iris_blue;
  font-size: 24px;
  margin-bottom: 10px;
  padding: 10px;

  &.visible {
    display: block;
  }
}

.uploadResult {
  display: none;
  color: $color_iris_blue;

  &.visible {
    display: block;
  }

  .totalErrors {
    color: $color_white;
    font-size: 24px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: $color_red;
  }

  .rowsImported {
    color: $color_white;
    font-size: 24px;
    margin-bottom: 10px;
    padding: 10px;
  }

  .error {
    background-color: $color_white;
    border-left: 8px solid $color_red;
    margin-bottom: 10px;
    padding: 10px;

    .rowData {
      word-wrap: break-word;
    }

    .message {
      margin-top: 10px;
    }
  }
}
