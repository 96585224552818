/////////////
// NAVIGATION
/////////////

nav {
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 25px;

  ul {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }

  li {
    padding-bottom: 25px;

    a {
      padding: 0;
      font-size: 24px;
      line-height: 36px;
      color: $color_white;
      text-transform: uppercase;
      opacity: $opacity_5;

      &:hover {
        opacity: $opacity_10;
      }
    }

    a.active {
      opacity: $opacity_10;
    }
  }

  #animatedMenu {
    position: relative;
    float: left;
    width: 100%;
    height: 8px;
    border-bottom: 2px solid $color_white;

    #animatedLine {
      position: absolute;
      display: block;
      float: left;
      width: 0;
      left: 0;
      top: 0;
      height: 6px;
      background: $color_white;
      transition: all .5s ease;
    }
  }
}

.nav {
  position: relative;
  width: 100%;
  text-align: center;

  li {
    display: inline-block;

    a {
      padding: 0 10px;
      font-size: 18px;
      line-height: 26px;
      color: $color_white;
      text-transform: uppercase;
      opacity: $opacity_5;

      &:hover {
        opacity: $opacity_10;
      }
    }

    a.active {
      opacity: $opacity_10;
    }
  }
}
