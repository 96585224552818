/////////
// INPUTS
/////////

// FORMS
.form {
  position: relative;
  float: left;
  width: 100%;

  .formRow {
    width: 50%;

    &:nth-child(odd) {
      padding-right: 20px;
      clear: both;
    }

    &:nth-child(even) {
      padding-left: 20px;

      label {
        left: 20px;
      }
    }

    &.isFull {
      clear: both;
      margin-top: 0;
      padding-right: 0 !important; // stylelint-disable-line declaration-no-important
      width: 100%;
    }

    input,
    textarea {
      width: 100%;
      font-size: 18px;
      border-bottom-width: 2px;
      border-bottom-style: solid;
      padding: 15px 0 8px;

      &.noLabel {
        padding-top: 0;
      }

      &:focus {
        & + label {
          @include vendor-prefix(transform, translate3d(0, -22px, 0));
          text-transform: uppercase;
          font-size: 12px;

          .labelTitle { // stylelint-disable-line max-nesting-depth
            opacity: $opacity_10;
          }
        }
      }
    }

    select {
      width: 100%;
      font-size: 18px;
      border-bottom-width: 2px;
      border-bottom-style: solid;
      padding: 7px 0;
    }

    input.errorInput {
      border-bottom-color: $color_red;
    }

    select.errorInput {
      border-bottom-color: $color_red;
    }

    label {
      @include vendor-prefix(transform, translate3d(0, 0, 0));
      position: absolute;
      width: 100%;
      top: 16px;
      left: 0;
      font-size: 18px;
      transition: all .2s ease-in-out;

      span.labelTitle {
        opacity: $opacity_5;
      }
    }

    label.selectLabel {
      position: relative;
      top: -9px;
      left: 0;
      transition: none;
      font-size: 12px;

      span {
        font-size: 12px;
        text-transform: uppercase;
      }
    }

    label.animate {
      @include vendor-prefix(transform, translate3d(0, -22px, 0));
      text-transform: uppercase;
      font-size: 12px;

      .labelTitle {
        opacity: $opacity_10;
      }

      .labelTitle.transparentFont {
        opacity: $opacity_5;
      }
    }

    input[data-empty="false"] {
      & + label {
        @include vendor-prefix(transform, translate3d(0, -22px, 0));
        text-transform: uppercase;
        font-size: 12px;
      }
    }

    .errorInputMsg {
      color: $color_red;
      font-size: 10px;
      text-transform: none;
      opacity: $opacity_10;

      & + label {
        @include vendor-prefix(transform, translate3d(0, -22px, 0));
        text-transform: uppercase;
        font-size: 12px;
      }
    }
  }

  .buttons {
    position: relative;
    float: right;
    clear: both;
    margin-top: 35px;

    button {
      margin-left: 50px;
      text-transform: uppercase;
      font-size: 14px;
    }

    .btnSuccess {
      @include vendor-prefix(border-radius, $border-radius);
      @include vendor-prefix(box-shadow, $btn-box-shadow-1);
      padding: 15px 50px 12px;
      background: $color_green_success;
      color: $color_white;
    }

    .btnDanger {
      color: $color_red;
      border-bottom: 1px solid;
    }

    .btnInfo {
      color: $color_grey;
      border-bottom: 1px solid;
    }
  }

  .infoIcon {
    @include image-2x($image_info_x2, 11px, 11px);
    position: relative;
    display: inline-block;
    width: 11px;
    height: 11px;
    margin-left: 8px;
    top: -1px;
    background: $image_info no-repeat center center/11px 11px;
    cursor: pointer;
  }

  .editSiteIDLink {
    float: right;
    margin-right: 20px;
  }
}

.twoInputsForm {
  position: relative;
  float: left;
  width: 100%;

  .formRow {
    margin-top: 50px;
    text-align: center;

    label {
      position: relative;
      display: inline-block;
      width: 100%;
      color: $color_white;

      span {
        position: relative;
        display: inline-block;
        font-size: 24px;
        text-transform: uppercase;
      }

      input {
        position: relative;
        float: left;
        width: 100%;
        margin-top: 20px;
        padding-bottom: 5px;
        font-size: 22px;
        line-height: 32px;
        text-align: center;
        border-bottom: 4px solid $color_white_5;
        color: $color_white;
      }

      input[type="text"] {
        text-transform: capitalize;
      }

      input.passwordInput {
        text-transform: none;
      }
    }
  }

  .formRow.inactive {
    label {
      opacity: $opacity_5;
    }
  }

  .formRow.active {
    input {
      border-bottom-color: $color_white;
    }
  }

  .noticeRow {
    margin-top: 20px;

    a {
      font-size: 14px;
      line-height: 16px;
      text-decoration: underline;
      color: $color_white;
      opacity: .3;
      text-transform: uppercase;

      &:hover {
        opacity: $opacity_10;
      }
    }
  }

  .buttonRow {
    margin-top: 40px;
  }

  button {
    @include vendor-prefix(border-radius, $border-radius);
    @include vendor-prefix(box-shadow, $btn-box-shadow-1);
    padding: 15px 65px 12px;
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;
    background: $color_white;
    color: $color_blue;
    opacity: .1;

    &:disabled {
      cursor: auto;
    }
  }

  button.activeBtn {
    opacity: $opacity_10;
    cursor: pointer;
  }

  button.redBtn {
    color: $color_red;
  }

  .togglePswd {
    @include vendor-prefix(box-shadow, none);
    position: absolute;
    bottom: 10px;
    right: 0;
    padding: 0;
    background: none;
    opacity: $opacity_5;
    color: $color_white;
    font-size: 14px;
  }
}

// INPUTS
input {
  padding: 0;
  border: none;
  background: none;
}

select {
  border: none;
}

option {
  padding: 0;
}

.asCheckbox {
  @include image-2x($image_tick_x2, 12px, 9px);
  opacity: 1 !important; // stylelint-disable-line declaration-no-important
  background: $image_tick no-repeat right center/12px 9px;
}
