.greenBg {
  background-color: #7ac143;
}
.greenBg .twoInputsForm .buttonRow button {
  color: #7ac143;
}

.blueBg {
  background-color: #00788a;
}
.blueBg .twoInputsForm .buttonRow button {
  color: #00788a;
}

.blueLightBg {
  background-color: #007188;
}

.blueDarkBg {
  background-color: #00466f;
}

.purpleLightBg {
  background-color: #754395;
}

.purpleBg {
  background-color: #593372;
}

.redBg {
  background-color: #e9382b;
}

.successBg {
  background-color: #86bc37;
}

.greenDarkBg {
  background-color: #77b247;
}

.bgAnimate {
  transition: all 0.2s ease-in-out;
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: "Arial", sans-serif;
  outline: 0;
}

html {
  position: relative;
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

body {
  position: relative;
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

#root,
.container {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
}

ul {
  margin: 0;
  padding: 0;
}
ul li {
  margin: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

.hidden {
  display: none;
}

.floatRight {
  float: right;
}

.floatLeft {
  float: left;
}

.clear {
  clear: both;
}

.transparentFont {
  opacity: 0.5;
}

.transparentLink {
  color: #fff;
  opacity: 0.5;
}

.noPadding {
  padding: 0 !important;
}

.fullWidth {
  width: 100% !important;
}

.textRight {
  text-align: right;
}

.content {
  position: relative;
  width: 960px;
  margin: 50px auto 0;
}

.colorWrapper {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
}
.colorWrapper.loggedOut {
  min-height: 100%;
  padding-bottom: 137px;
}
.colorWrapper.loggedIn {
  min-height: 100%;
  height: auto;
  padding-bottom: 80px;
}

.loader-center {
  display: flex;
  justify-content: center;
  width: 100%;
}

.middleContainer {
  position: relative;
}

.middleBox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 300px;
  margin: 0 auto;
  text-align: center;
}
.middleBox h2 {
  font-size: 18px;
  line-height: 26px;
  color: #fff;
  text-transform: uppercase;
}
.middleBox .info {
  margin: 45px 0 0;
  font-size: 24px;
  line-height: 36px;
  color: #fff;
  text-transform: uppercase;
}

.loadingBox div {
  margin: 0 auto;
}

.searchBar {
  position: relative;
  float: left;
  width: 480px;
  padding-bottom: 7px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}
.searchBar input.searchInput {
  position: relative;
  width: 100%;
  padding-left: 25px;
  font-size: 16px;
  line-height: 18px;
  color: #fff;
  background: url("./images/search.png") no-repeat left center/18px 18px;
}
.searchBar ::-webkit-input-placeholder {
  color: #fff;
  opacity: 0.5;
}
.searchBar :-moz-placeholder {
  color: #fff;
  opacity: 0.5;
}
.searchBar ::-moz-placeholder {
  color: #fff;
  opacity: 0.5;
}
.searchBar :-ms-input-placeholder {
  color: #fff;
  opacity: 0.5;
}
.searchBar .cancelSearch {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 15px;
  height: 15px;
  background: url("./images/x-copy.png") no-repeat center center/15px 15px;
}
.searchBar .cancelSearch.visible {
  display: block;
}

.simpleMsg {
  position: relative;
  float: left;
  width: 100%;
  margin-top: 30px;
}
.simpleMsg p {
  color: #fff;
  font-size: 24px;
  text-transform: uppercase;
}
.simpleMsg .asButton {
  margin-top: 45px;
}
.simpleMsg button {
  margin-top: 25px;
  padding-left: 25px;
  padding-right: 25px;
}

#notFoundBox {
  width: 450px;
}
#notFoundBox div {
  height: 144px;
  font-size: 180px;
  font-weight: bold;
  color: #fff;
  background: url("./images/shape-940.png") no-repeat center center/450px 104px;
}
#notFoundBox p {
  padding: 45px 0 35px;
  font-size: 18px;
  color: #fff;
}
#notFoundBox .asBtn {
  border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -webkit-border-radius: 2px;
  box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.05);
  display: inline-block;
  padding: 18px 45px 14px;
  font-size: 18px;
  text-transform: uppercase;
  background: #fff;
  color: #007188;
}

h1 {
  text-transform: uppercase;
}

h2 {
  text-transform: uppercase;
  margin: 0;
  font-size: 36px;
  line-height: 54px;
}

h3 {
  text-transform: uppercase;
  margin: 0;
}

h4 {
  text-transform: uppercase;
}

p {
  font-size: 16px;
  line-height: 24px;
}

.filterRow {
  padding: 30px 0 0;
}
.filterRow .filterContainer {
  padding: 20px 0 0;
}
.filterRow .filterContainer #dateRangeList {
  margin: 0;
}
.filterRow .filterContainer .columnsLabels {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
}
.filterRow .filterContainer .columnsLabels .limitedDropdown {
  min-width: 290px;
  max-width: 310px;
}
.filterRow .filterContainer .columnsLabels .limitedDropdown ul {
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
}
.filterRow .filterContainer .toggleBtn.blueBtn {
  color: #007188;
  background: url("./images/down-arrow-reports.png") no-repeat right top 1px/12px 8px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .filterRow .filterContainer .toggleBtn.blueBtn {
    /* on retina, use image that's scaled by 2 */
    background-image: url("./images/down-arrow-reports@2x.png");
    background-size: 12px 8px;
  }
}
.filterRow .filterTooltip label {
  cursor: pointer;
  width: inherit;
}
.filterRow .filterTooltip label span.label {
  float: inherit;
  margin-left: 4px;
}
.filterRow .dateBtn {
  position: relative;
}
.filterRow .dateBtn .labelAbove {
  bottom: 20px;
  position: absolute;
  width: calc(100% - 25px);
}
.filterRow .dateBtn .label {
  display: inline-block;
}

#reportHeader {
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 30px;
}
#reportHeader.is-hidden {
  margin-bottom: 0;
}
#reportHeader .reportHeaderBtns {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
}
#reportHeader .searchRow {
  position: relative;
  float: left;
  width: 100%;
  margin-top: 35px;
}
#reportHeader .searchBar {
  margin-top: 13px;
}
#reportHeader .actionButton {
  background: #7ac143;
}
#reportHeader .actionButton:disabled {
  cursor: auto;
  opacity: 0.5;
}
#reportHeader .filterContainer {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding: 20px 0 0;
}
#reportHeader .columnsLabels:not(:first-of-type) {
  position: relative;
  float: right;
  width: 373px;
  text-align: right;
}
#reportHeader .columnsLabels:first-of-type {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
}
#reportHeader .columnsLabels #dateRangeList {
  margin-top: 0;
}
#reportHeader .columnsLabels #dateRangeList .selectButton {
  background-position-y: 4px;
  white-space: nowrap;
}
#reportHeader .columnsLabels #dateRangeList li:first-of-type ul {
  flex-direction: column;
}
#reportHeader .columnsLabels #dateRangeList li:first-of-type ul.visible {
  display: flex;
}
#reportHeader .columnsLabels li {
  display: inline-block;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
}
#reportHeader .columnsLabels li select {
  color: #fff;
  background: none;
  font-size: 14px;
  text-transform: uppercase;
}
#reportHeader .columnsLabels li select option {
  background: #00466f;
}
#reportHeader .columnsLabels .limitedDropdown {
  min-width: 290px;
  max-width: 310px;
}
#reportHeader .columnsLabels .limitedDropdown ul {
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
}
#reportHeader .filterTooltip {
  min-width: 200px;
}
#reportHeader .filterTooltip label {
  cursor: pointer;
}

#reportList .listRow {
  color: #007188;
}
#reportList .listRow td {
  position: relative;
  padding: 0;
}
#reportList .listRow td:first-child {
  width: 75%;
}
#reportList .listRow .rowBorder {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  border-left-style: solid;
  border-left-width: 8px;
}
#reportList .listRow .actionTooltip {
  top: 50px;
  right: 25px;
}
#reportList a {
  position: relative;
  width: 100%;
  padding: 17px 25px 13px;
  color: #007188;
  text-decoration: none;
}
#reportList .asLink {
  float: right;
  margin-left: 0;
  padding-right: 25px;
  color: #007188;
}
#reportList .viewTd .asLink {
  float: right;
  margin-left: 0;
  padding-right: 25px;
  color: #007188;
  background: url("./images/arrow-blue.png") no-repeat right calc(50% - 2px)/20px 14px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #reportList .viewTd .asLink {
    /* on retina, use image that's scaled by 2 */
    background-image: url("./images/arrow-blue@2x.png");
    background-size: 20px 14px;
  }
}
#reportList .pic {
  float: right;
}

.reportDetail {
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  position: relative;
  float: left;
  width: 100%;
  opacity: 1;
  padding: 30px;
  background: #fff;
  border-left-style: solid;
  border-left-width: 8px;
}
.reportDetail.is-updating {
  pointer-events: none;
  opacity: 0.85;
}
.reportDetail h2 {
  color: #007188;
}
.reportDetail .closeButton {
  background: url("./images/x.png") no-repeat center center/23px 23px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .reportDetail .closeButton {
    /* on retina, use image that's scaled by 2 */
    background-image: url("./images/x@2x.png");
    background-size: 23px 23px;
  }
}
.reportDetail table {
  position: relative;
  float: left;
  width: 100%;
  margin-top: 25px;
}
.reportDetail table tr:nth-child(odd) {
  background-color: #f3f4f5;
}
.reportDetail table tr td {
  padding: 14px 10px 12px;
  color: #007188;
  vertical-align: top;
}
.reportDetail table tr td:first-child {
  width: 290px;
  border-right: 2px solid #fff;
  text-transform: uppercase;
}
.reportDetail table tr td div:not(.formRow) {
  width: 100%;
  padding: 14px 10px 12px;
}
.reportDetail table tr td div.imgContainer {
  position: relative;
  float: left;
  padding: 0;
  margin-bottom: 10px;
  max-width: 267px;
}
.reportDetail table tr td div.imgContainer img {
  display: block;
  width: 100%;
}
.reportDetail table tr td.hazardTd div:nth-child(even) {
  background-color: #fff;
}
.reportDetail table tr td.categoryTd div:nth-child(even) {
  background-color: #f3f4f5;
}
.reportDetail .reportLabel label {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  padding-left: 30px;
  position: relative;
}
.reportDetail .reportLabel input[type=checkbox] {
  display: none;
}
.reportDetail .reportLabel input[type=checkbox] + span {
  border: 1px solid #007188;
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  width: 20px;
}
.reportDetail .reportLabel input[type=checkbox]:checked + span {
  background-image: url("./images/tick-blue.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 12px 9px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .reportDetail .reportLabel input[type=checkbox]:checked + span {
    /* on retina, use image that's scaled by 2 */
    background-image: url("./images/tick-blue@2x.png");
    background-size: 12px 9px;
  }
}
.reportDetail .reportTextarea {
  background-color: transparent;
  border: 0 none;
  border-bottom-color: #007188;
}
.reportDetail .reportInput {
  border-bottom-color: #007188;
}
.reportDetail .reportUpdate {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  width: 100%;
}
.reportDetail .reportUpdate .actionButton {
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  background-color: #007188;
  opacity: 1;
}
.reportDetail .reportUpdate .actionButton:disabled {
  cursor: auto;
  opacity: 0.5;
}

.reportDetail.reportDownload {
  border: none;
}
.reportDetail.reportDownload label {
  display: inline-block;
}
.reportDetail.reportDownload input[type=checkbox] {
  display: none;
}
.reportDetail.reportDownload input[type=checkbox] + span {
  float: right;
  width: 20px;
  height: 20px;
  background-color: #7ac143;
}
.reportDetail.reportDownload input[type=checkbox]:checked + span {
  background-image: url("./images/tick.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 12px 9px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .reportDetail.reportDownload input[type=checkbox]:checked + span {
    /* on retina, use image that's scaled by 2 */
    background-image: url("./images/tick@2x.png");
    background-size: 12px 9px;
  }
}
.reportDetail.reportDownload #search::-webkit-input-placeholder {
  color: #007188;
  opacity: 0.5;
}
.reportDetail.reportDownload #search::-moz-placeholder {
  color: #007188;
  opacity: 0.5;
}
.reportDetail.reportDownload #search:-ms-input-placeholder {
  color: #007188;
  opacity: 0.5;
}
.reportDetail.reportDownload .downloadHeader label {
  margin-right: 40px;
  color: #007188;
  font-size: 16px;
  text-transform: capitalize;
}
.reportDetail.reportDownload .downloadHeader label span {
  float: left;
  margin-right: 15px;
}
.reportDetail.reportDownload .downloadHeader #dateRangeList ul {
  margin-top: -20px;
}
.reportDetail.reportDownload .downloadHeader .datepicker {
  margin-top: -20px;
}
.reportDetail.reportDownload .downloadHeader .searchRow {
  position: relative;
  float: left;
  width: 100%;
  margin-top: 35px;
}
.reportDetail.reportDownload .downloadHeader .searchRow .searchBar {
  width: 580px;
  border-bottom: 2px solid #e7f2f3;
  margin-top: 13px;
}
.reportDetail.reportDownload .downloadHeader .searchRow input.searchInput {
  color: #007188;
  background: url("./images/search.png") no-repeat left center/18px 18px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .reportDetail.reportDownload .downloadHeader .searchRow input.searchInput {
    /* on retina, use image that's scaled by 2 */
    background-image: url("./images/search@2x.png");
    background-size: 18px 18px;
  }
}
.reportDetail.reportDownload .downloadHeader .searchRow .cancelSearch {
  background: url("./images/x.png") no-repeat center center/15px 15px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .reportDetail.reportDownload .downloadHeader .searchRow .cancelSearch {
    /* on retina, use image that's scaled by 2 */
    background-image: url("./images/x@2x.png");
    background-size: 15px 15px;
  }
}
.reportDetail.reportDownload .downloadHeader .searchRow .actionButton {
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  background: #7ac143;
  box-shadow: none;
  opacity: 1;
}
.reportDetail.reportDownload .downloadHeader .searchRow .actionButton:disabled {
  cursor: auto;
  opacity: 0.5;
}
.reportDetail.reportDownload .downloadHeader .columnsLabels {
  position: relative;
  float: right;
  width: 307px;
  text-align: right;
}
.reportDetail.reportDownload .downloadHeader .columnsLabels li {
  display: inline-block;
  width: 50%;
  font-size: 14px;
  color: #007188;
  text-transform: uppercase;
}
.reportDetail.reportDownload .downloadHeader .columnsLabels li:last-child {
  text-align: right;
}
.reportDetail.reportDownload .downloadHeader .columnsLabels li .toggleBtn {
  color: #007188;
  background: url("./images/down-arrow-reports.png") no-repeat right top 1px/12px 8px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .reportDetail.reportDownload .downloadHeader .columnsLabels li .toggleBtn {
    /* on retina, use image that's scaled by 2 */
    background-image: url("./images/down-arrow-reports@2x.png");
    background-size: 12px 8px;
  }
}
.reportDetail.reportDownload .downloadHeader .columnsLabels .filterTooltip ul {
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.reportDetail.reportDownload .downloadHeader .columnsLabels .filterTooltip input[type=checkbox] + span {
  float: right;
  margin-top: -3px;
  margin-right: 0;
  background-color: #7ac143;
}
.reportDetail.reportDownload .downloadHeader .columnsLabels .filterTooltip input[type=checkbox]:checked + span {
  background-image: url("./images/tick.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 12px 9px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .reportDetail.reportDownload .downloadHeader .columnsLabels .filterTooltip input[type=checkbox]:checked + span {
    /* on retina, use image that's scaled by 2 */
    background-image: url("./images/tick@2x.png");
    background-size: 12px 9px;
  }
}
.reportDetail.reportDownload .downloadHeader .columnsLabels .filterTooltip input[type=radio] + span {
  float: right;
  margin-top: -3px;
  margin-right: 0;
  background-color: #7ac143;
}
.reportDetail.reportDownload .downloadHeader .columnsLabels .filterTooltip input[type=radio]:checked + span {
  background-image: url("./images/tick.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 12px 9px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .reportDetail.reportDownload .downloadHeader .columnsLabels .filterTooltip input[type=radio]:checked + span {
    /* on retina, use image that's scaled by 2 */
    background-image: url("./images/tick@2x.png");
    background-size: 12px 9px;
  }
}
.reportDetail.reportDownload #downloadList table {
  margin-top: 50px;
  border-collapse: collapse;
}
.reportDetail.reportDownload #downloadList table tr {
  border: none;
}
.reportDetail.reportDownload #downloadList table tr:nth-child(odd) {
  background-color: #e7f2f3;
}
.reportDetail.reportDownload #downloadList table tr td {
  border: none;
}
.reportDetail.reportDownload #downloadList table tr td input[type=checkbox] + span {
  background-color: #dbebed;
}

.reportCheckboxRow label {
  display: inline-block;
  margin-top: 9px;
}
.reportCheckboxRow input[type=checkbox] {
  display: none;
}
.reportCheckboxRow input[type=checkbox] + span {
  float: left;
  width: 20px;
  height: 20px;
  background-color: #e7f2f3;
}
.reportCheckboxRow input[type=checkbox]:checked + span {
  background-image: url("./images/tick.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 12px 9px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .reportCheckboxRow input[type=checkbox]:checked + span {
    /* on retina, use image that's scaled by 2 */
    background-image: url("./images/tick@2x.png");
    background-size: 12px 9px;
  }
}

.reportStatus {
  color: #fff;
  display: flex;
  float: right;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  margin-bottom: 7px;
  position: relative;
  text-transform: uppercase;
  z-index: 2;
}
.reportStatus .reportStatusLabel {
  opacity: 0.5;
}
.reportStatus .reportStatusSeparator {
  padding: 0 5px;
  opacity: 0.5;
}
.reportStatus .reportStatusOption {
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  cursor: pointer;
  opacity: 0.5;
}
.reportStatus .reportStatusOption:hover, .reportStatus .reportStatusOption.active {
  opacity: 1;
}

#siteHeader {
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 30px;
}
#siteHeader .searchRow {
  position: relative;
  float: left;
  width: 100%;
  margin-top: 35px;
}
#siteHeader .actionButton {
  background: #ac7ba3;
}
#siteHeader .columnsLabels {
  position: relative;
  float: right;
  width: 360px;
}
#siteHeader .columnsLabels li {
  display: inline-block;
  width: 50%;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
}
#siteHeader .columnsLabels li:last-child {
  text-align: right;
}
#siteHeader .columnsLabels li select {
  color: #fff;
  background: none;
  font-size: 14px;
  text-transform: uppercase;
}
#siteHeader .columnsLabels li select option {
  background: #00466f;
}

#siteHeader.activeHeader .columnsLabels li:last-child {
  width: 83px;
}

#siteHeader.archivedHeader .columnsLabels li:last-child {
  width: 123px;
}

#siteList .listRow {
  border-bottom-color: #593372;
}
#siteList .listRow td {
  color: #593372;
}
#siteList .listRow span {
  color: #593372;
}
#siteList .listRow a {
  color: #593372;
}
#siteList .listRow .asLink {
  color: #593372;
}
#siteList .listRow .wideTd {
  width: 586px;
}
#siteList .listRow .narrowTd {
  width: 75px;
  text-align: center;
}
#siteList .actionTooltip {
  background-color: #ac7ba3;
}
#siteList .actionTooltip::before {
  border-bottom-color: #ac7ba3;
}
#siteList .actionTooltip::after {
  border-bottom-color: #ac7ba3;
}

.siteCard {
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  position: relative;
  float: left;
  width: 100%;
  padding: 40px 30px;
  background: #fff;
  opacity: 1;
}
.siteCard.is-loading {
  pointer-events: none;
  opacity: 0.85;
}
.siteCard h2 {
  color: #593372;
}
.siteCard h2 input {
  font-size: 36px;
  text-transform: uppercase;
  color: #593372;
}
.siteCard p {
  color: #593372;
}
.siteCard label {
  color: #593372;
}
.siteCard input {
  color: #593372;
  border-color: rgba(89, 51, 114, 0.5);
}
.siteCard select {
  color: #593372;
  border-color: rgba(89, 51, 114, 0.5);
}
.siteCard .closeButton {
  background: url("./images/x-sites.png") no-repeat center center/23px 23px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .siteCard .closeButton {
    /* on retina, use image that's scaled by 2 */
    background-image: url("./images/x-sites@2x.png");
    background-size: 23px 23px;
  }
}
.siteCard .form::-webkit-input-placeholder {
  color: #593372;
  opacity: 0.5;
}
.siteCard .form:-moz-placeholder {
  color: #593372;
  opacity: 0.5;
}
.siteCard .form::-moz-placeholder {
  color: #593372;
  opacity: 0.5;
}
.siteCard .form:-ms-input-placeholder {
  color: #593372;
  opacity: 0.5;
}
.siteCard .form .formRow:nth-child(even) {
  padding-right: 20px;
  padding-left: 0;
}
.siteCard .form .formRow:nth-child(even) label {
  left: 0;
}
.siteCard .form .formRow:nth-child(odd) {
  padding-left: 20px;
  padding-right: 0;
  clear: none;
}
.siteCard .form .formRow:nth-child(odd) label {
  left: 20px;
}
.siteCard .form .formRow input[type=number] {
  -webkit-appearance: none;
}
.siteCard .form .formRow input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.siteCard .form .formRow input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.siteCard .form .formRow label {
  color: #593372;
}
.siteCard .form .formRow.fullWidth label {
  left: 0;
}
.siteCard .form .btnSuccess {
  background-color: #593372;
  opacity: 0.5;
  cursor: default;
}
.siteCard .form .btnSuccess.activeBtn {
  opacity: 1;
  cursor: pointer;
}
.siteCard .mapContainer {
  position: relative;
  float: left;
  width: 100%;
  margin: 20px 0;
}
.siteCard .infoTooltip::before {
  left: 76px;
}
.siteCard .infoTooltip::after {
  left: 76px;
}

#userHeader {
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 30px;
}
#userHeader .searchRow {
  position: relative;
  float: left;
  width: 100%;
}
#userHeader .searchBar {
  margin-top: 13px;
}
#userHeader .actionButton {
  background: #78bedf;
}
#userHeader .columnsLabels {
  position: relative;
  float: right;
  width: 383px;
}
#userHeader .columnsLabels li {
  display: inline-block;
  width: 50%;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
}
#userHeader .columnsLabels li:last-child {
  text-align: right;
}
#userHeader .columnsLabels li select {
  color: #fff;
  background: none;
  font-size: 14px;
  text-transform: uppercase;
}
#userHeader .columnsLabels li select option {
  background: #00466f;
}
#userHeader .filterTooltip {
  min-width: 220px;
  margin-top: 30px;
}

#userList .listRow {
  border-bottom-color: #00466f;
}
#userList .listRow td {
  color: #00466f;
  position: relative;
  padding: 0;
}
#userList .listRow td:first-child a {
  padding-left: 20px;
}
#userList .listRow td:last-child a {
  padding-right: 20px;
}
#userList .listRow span {
  color: #00466f;
}
#userList .listRow a {
  color: #00466f;
  float: none;
  display: inline-block;
  width: 100%;
  text-decoration: none;
  padding: 17px 0 14px;
}
#userList .listRow .asLink {
  color: #00466f;
  margin: 0;
  padding: 17px 20px 14px 30px;
}
#userList .listRow .wideTd {
  width: 586px;
}
#userList .listRow .narrowTd {
  width: 75px;
  text-align: left;
}
#userList .listRow .actionTooltip {
  top: 50px;
  right: 20px;
}

.userCard {
  position: relative;
  float: left;
  width: 100%;
  padding: 40px 30px;
  background: #fff;
}
.userCard h2 {
  color: #00466f;
}
.userCard p {
  color: #00466f;
  max-width: 862px;
}
.userCard label {
  color: #00466f;
}
.userCard input {
  color: #00466f;
  border-color: rgba(0, 70, 111, 0.5);
}
.userCard select {
  color: #00466f;
  border-color: rgba(0, 70, 111, 0.5);
}
.userCard .closeButton {
  background: url("./images/x-users.png") no-repeat center center/23px 23px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .userCard .closeButton {
    /* on retina, use image that's scaled by 2 */
    background-image: url("./images/x-users@2x.png");
    background-size: 23px 23px;
  }
}
.userCard .form::-webkit-input-placeholder {
  color: #00466f;
  opacity: 0.5;
}
.userCard .form:-moz-placeholder {
  color: #00466f;
  opacity: 0.5;
}
.userCard .form::-moz-placeholder {
  color: #00466f;
  opacity: 0.5;
}
.userCard .form:-ms-input-placeholder {
  color: #00466f;
  opacity: 0.5;
}
.userCard .form .btnInfo {
  color: #00466f;
  opacity: 0.5;
}

#userForm .toggleBtn {
  float: left;
  width: 100%;
  padding-bottom: 7px;
  font-size: 18px;
  color: #00466f;
  text-align: left;
  text-transform: none;
  background: url("./images/drop-down-arrow-user.png") no-repeat right top 1px/12px 8px;
  border-bottom: 2px solid rgba(0, 70, 111, 0.5);
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #userForm .toggleBtn {
    /* on retina, use image that's scaled by 2 */
    background-image: url("./images/drop-down-arrow-user@2x.png");
    background-size: 12px 8px;
  }
}
#userForm .filterTooltip {
  width: calc(100% - 20px);
  top: 40px;
  padding: 10px;
}
#userForm .filterTooltip::before {
  right: 0;
  left: 15px;
}
#userForm .filterTooltip::after {
  right: 0;
  left: 15px;
}
#userForm .filterTooltip ul {
  margin: 0;
  padding: 0;
}
#userForm .filterTooltip ul li {
  padding: 10px;
}
#userForm .filterTooltip label {
  transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -webkit-transform: none;
  transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -webkit-transition: none;
  position: relative;
  width: 100%;
  left: 0;
  top: 0;
}

#header {
  padding: 30px;
}
#header #logo {
  position: relative;
  display: inline-block;
  width: 90px;
  height: 25px;
  background: url("./images/wates-logo.png") no-repeat center center/contain;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #header #logo {
    /* on retina, use image that's scaled by 2 */
    background-image: url("./images/wates-logo@2x.png");
    background-size: 90px 25px;
  }
}
#header h1 {
  display: inline-block;
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  opacity: 0.5;
}
#header #userInfo {
  float: right;
  width: auto;
}
#header #userInfo li {
  position: relative;
  text-align: right;
}
#header #userInfo li button {
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  text-transform: uppercase;
  opacity: 0.5;
  cursor: pointer;
}
#header #userInfo li button .downArrow {
  display: inline-block;
  margin-left: 6px;
  width: 12px;
  height: 8px;
  background: url("./images/down-arrow.png") no-repeat center center/contain;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #header #userInfo li button .downArrow {
    /* on retina, use image that's scaled by 2 */
    background-image: url("./images/down-arrow@2x.png");
    background-size: 12px 8px;
  }
}
#header #userInfo li ul {
  border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -webkit-border-radius: 2px;
  position: absolute;
  right: 0;
  z-index: 100;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  -ms-box-sizing: content-box;
  box-sizing: content-box;
}
#header #userInfo li ul li {
  padding: 35px 30px;
  text-align: center;
}
#header #userInfo li ul li span {
  font-size: 20px;
  line-height: 26px;
}
#header #userInfo li ul li a {
  padding-right: 25px;
  color: #fff;
  background: url("./images/arrow-white.png") no-repeat right top/20px 13px;
  white-space: nowrap;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #header #userInfo li ul li a {
    /* on retina, use image that's scaled by 2 */
    background-image: url("./images/arrow-white@2x.png");
    background-size: 20px 13px;
  }
}

#footer {
  position: absolute;
  bottom: 0;
  float: left;
  width: 100%;
  margin-bottom: 31px;
  background-image: url("./images/men.png");
  background-repeat: no-repeat;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #footer {
    /* on retina, use image that's scaled by 2 */
    background-image: url("./images/men@2x.png");
    background-size: 190px 288px;
  }
}

#floatBanner {
  float: left;
  height: 75px;
  width: 100%;
}
#floatBanner .float-inner-wrapper {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -ms-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  float: none;
  position: relative;
  z-index: 20;
}
#floatBanner.is-download-banner, #floatBanner.is-chart-banner {
  margin-top: -20px;
}
#floatBanner #floatBannerInner {
  background: #007188;
  height: 75px;
  margin: 0 auto;
  padding: 20px 0;
  width: 960px;
  -webkit-backface-visibility: hidden;
}
#floatBanner #floatBannerInner.is-charts {
  background-color: #754395;
}
#floatBanner #floatBannerInner button {
  display: inline-block;
}
#floatBanner #floatBannerInner span {
  margin-top: 10px;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
}
#floatBanner #floatBannerInner .downloadChecks {
  margin-top: 10px;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
  margin-left: 15px;
  text-decoration: underline;
  opacity: 0.2;
}
#floatBanner #floatBannerInner .downloadChecks.active {
  opacity: 1;
}
#floatBanner #floatBannerInner .deleteChecks {
  margin-top: 10px;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
  margin-left: 15px;
  text-decoration: underline;
  opacity: 0.2;
}
#floatBanner #floatBannerInner .deleteChecks.active {
  opacity: 1;
}
#floatBanner.floatAbove .float-inner-wrapper {
  box-shadow: 0 4px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 3px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 4px 3px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 4px 3px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.chartDetails {
  width: 100%;
}

.chartContainer {
  margin: 0 0 30px;
}

.chartTitle {
  padding-bottom: 10px;
}

button {
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
}

.actionButton {
  border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -webkit-border-radius: 2px;
  box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.05);
  display: inline-block;
  padding: 14px 40px 11px;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
}
.actionButton.red {
  background: #e9382b !important;
}
.actionButton.yellow {
  background: #ffb700 !important;
}

.closeButton {
  display: inline-block;
  width: 23px;
  height: 23px;
  margin-top: 12px;
}

.successButton {
  border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -webkit-border-radius: 2px;
  padding: 12px 20px 10px;
  background-color: #7ac143;
  color: #fff;
  text-transform: uppercase;
  opacity: 0.5;
  cursor: default;
}
.successButton.active {
  opacity: 1;
  cursor: pointer;
}

.errorBtn {
  border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -webkit-border-radius: 2px;
  padding: 12px 20px 10px;
  background-color: #e9382b;
  color: #fff;
  text-transform: uppercase;
  opacity: 0.5;
  cursor: default;
}
.errorBtn.active {
  opacity: 1;
  cursor: pointer;
}

.toggleBtn {
  padding-right: 25px;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  background: url("./images/down-arrow.png") no-repeat right top 1px/12px 8px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .toggleBtn {
    /* on retina, use image that's scaled by 2 */
    background-image: url("./images/down-arrow@2x.png");
    background-size: 12px 8px;
  }
}

.uploadBtn {
  cursor: pointer;
}

.asButton {
  border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -webkit-border-radius: 2px;
  box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.05);
  display: inline-block;
  padding: 15px 65px 12px;
  font-size: 18px;
  line-height: 26px;
  text-transform: uppercase;
  color: #7ac143;
  background: #fff;
}

#dateRangeList {
  margin-top: 12px;
}
#dateRangeList .selectButton {
  display: inline-block;
  padding-right: 20px;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  background: url("./images/down-arrow.png") no-repeat right 1px/12px 8px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #dateRangeList .selectButton {
    /* on retina, use image that's scaled by 2 */
    background-image: url("./images/down-arrow@2x.png");
    background-size: 12px 8px;
  }
}
#dateRangeList li {
  position: relative;
}
#dateRangeList ul {
  border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -webkit-border-radius: 2px;
  box-shadow: 0 5px 0 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 5px 0 0 rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 5px 0 0 rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 5px 0 0 rgba(0, 0, 0, 0.05);
  display: none;
  position: absolute;
  width: 290px;
  margin-top: 20px;
  z-index: 10;
  background: #7ac143;
}
#dateRangeList ul::before {
  content: "";
  display: block;
  position: absolute;
  left: 10px;
  bottom: 100%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #7ac143;
}
#dateRangeList ul::after {
  content: "";
  display: block;
  position: absolute;
  left: 10px;
  bottom: 100%;
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-bottom-color: #7ac143;
}
#dateRangeList ul.visible {
  display: block;
}
#dateRangeList ul li {
  padding: 10px 15px 8px;
  background: #7ac143;
}
#dateRangeList ul li button {
  width: 100%;
  font-size: 14px;
  text-align: left;
  color: #fff;
  opacity: 0.5;
}
#dateRangeList ul li button.active {
  opacity: 1;
  background: url("./images/tick.png") no-repeat right center/14px 10px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #dateRangeList ul li button.active {
    /* on retina, use image that's scaled by 2 */
    background-image: url("./images/tick@2x.png");
    background-size: 14px 10px;
  }
}
#dateRangeList ul li button.datePickerButton {
  opacity: 1;
  background: url("./images/date-icon.png") no-repeat right center/15px 14px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #dateRangeList ul li button.datePickerButton {
    /* on retina, use image that's scaled by 2 */
    background-image: url("./images/date-icon@2x.png");
    background-size: 15px 14px;
  }
}
#dateRangeList ul li:first-child {
  padding-top: 20px;
}
#dateRangeList ul li:last-child {
  margin-top: 10px;
  padding: 15px 15px;
  background: #77b247;
}

.dateRangeInput {
  display: inline-block;
  width: 288px;
  padding: 5px 0;
  border-bottom: 1px solid #e7f2f3;
  cursor: pointer;
}

.datepicker {
  display: none;
  position: absolute;
  margin-top: 20px;
  max-width: 280px;
  z-index: 20;
}
.datepicker::before {
  content: "";
  display: block;
  position: absolute;
  left: 10px;
  bottom: 100%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #7ac143;
}
.datepicker::after {
  content: "";
  display: block;
  position: absolute;
  left: 10px;
  bottom: 100%;
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-bottom-color: #7ac143;
}
.datepicker.visible {
  display: block;
}
.datepicker .rdrCalendarWrapper {
  background-color: #7ac143 !important;
  padding: 10px;
}
.datepicker .rdrMonthAndYearWrapper {
  display: flex;
  justify-content: space-between;
  margin: 5px 0 15px;
}
.datepicker .rdrMonthAndYearWrapper select {
  background: none;
  color: #fff;
  text-transform: uppercase;
}
.datepicker .rdrMonthAndYearWrapper .rdrMonthPicker {
  margin-right: 10px;
}
.datepicker .rdrNextPrevButton {
  display: inline-block;
  margin-top: -2px !important;
  background-color: #7ac143 !important;
  width: 18px;
  height: 18px;
}
.datepicker .rdrNextPrevButton i {
  border-style: solid;
  display: block;
  width: 0;
  height: 0;
  padding: 0;
}
.datepicker .rdrNextPrevButton.rdrPprevButton i {
  margin: 0 0 0 1px;
  border-width: 4px 6px 4px 4px;
  border-color: transparent #fff transparent transparent !important;
}
.datepicker .rdrNextPrevButton.rdrNextButton i {
  margin: 0 0 0 7px;
  border-width: 4px 4px 4px 6px;
  border-color: transparent transparent transparent #fff !important;
}
.datepicker .rdrWeekDays {
  display: flex;
}
.datepicker .rdrDays {
  display: flex;
  flex-wrap: wrap;
}
.datepicker .rdrWeekDay,
.datepicker .rdrDay {
  align-items: center;
  color: #fff !important;
  display: flex;
  font-size: 14px !important;
  height: 37px;
  justify-content: center;
  position: relative;
  width: 37px;
}
.datepicker .rdrWeekDay.rdrWeekDay,
.datepicker .rdrDay.rdrWeekDay {
  align-items: flex-start;
  font-size: 12px !important;
  font-weight: 600;
  margin-bottom: 1px;
  text-transform: uppercase;
}
.datepicker .rdrWeekDay .rdrDayNumber,
.datepicker .rdrDay .rdrDayNumber {
  z-index: 1;
}
.datepicker .rdrWeekDay.rdrDayPassive,
.datepicker .rdrDay.rdrDayPassive {
  opacity: 0.2 !important;
}
.datepicker .rdrWeekDay .rdrStartEdge,
.datepicker .rdrWeekDay .rdrEndEdge,
.datepicker .rdrWeekDay .rdrInRange,
.datepicker .rdrDay .rdrStartEdge,
.datepicker .rdrDay .rdrEndEdge,
.datepicker .rdrDay .rdrInRange {
  background-color: #fff !important;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.datepicker .rdrWeekDay .rdrStartEdge + .rdrDayNumber,
.datepicker .rdrWeekDay .rdrEndEdge + .rdrDayNumber,
.datepicker .rdrWeekDay .rdrInRange + .rdrDayNumber,
.datepicker .rdrDay .rdrStartEdge + .rdrDayNumber,
.datepicker .rdrDay .rdrEndEdge + .rdrDayNumber,
.datepicker .rdrDay .rdrInRange + .rdrDayNumber {
  color: #7ac143 !important;
}
.datepicker .rdr-Calendar {
  background-color: #7ac143 !important;
}
.datepicker .rdr-Calendar span {
  padding: 2px 0;
}
.datepicker .rdr-Calendar .rdr-Day {
  color: #fff !important;
  font-size: 14px !important;
}
.datepicker .rdr-Calendar .rdr-Day.is-passive {
  opacity: 0.2 !important;
}
.datepicker .rdr-Calendar .rdr-Day.is-selected {
  background-color: #fff !important;
  color: #7ac143 !important;
}
.datepicker .rdr-Calendar .rdr-Day.is-inRange {
  background-color: #fff !important;
  color: #7ac143 !important;
}
.datepicker .rdr-Calendar .rdr-MonthAndYear span {
  color: #fff !important;
  text-transform: uppercase;
}
.datepicker .rdr-Calendar .rdr-MonthAndYear button {
  margin-top: -2px !important;
  background-color: #7ac143 !important;
}
.datepicker .rdr-Calendar .rdr-MonthAndYear button.prev i {
  border-color: transparent #fff transparent transparent !important;
}
.datepicker .rdr-Calendar .rdr-MonthAndYear button.next i {
  border-color: transparent transparent transparent #fff !important;
}
.datepicker .rdr-Calendar .rdr-WeekDay {
  color: #fff !important;
  text-transform: uppercase;
}
.datepicker .doneButton {
  width: 100%;
  padding: 20px 21px 17px;
  background: #7ab220;
}
.datepicker .doneButton button {
  width: 100%;
  font-size: 16px;
  color: #fff;
  opacity: 0.5;
  background: url("./images/tick.png") no-repeat right center/14px 10px;
  text-align: left;
  cursor: default;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .datepicker .doneButton button {
    /* on retina, use image that's scaled by 2 */
    background-image: url("./images/tick@2x.png");
    background-size: 14px 10px;
  }
}
.datepicker .doneButton button.active {
  opacity: 1;
  cursor: pointer;
}

.form {
  position: relative;
  float: left;
  width: 100%;
}
.form .formRow {
  width: 50%;
}
.form .formRow:nth-child(odd) {
  padding-right: 20px;
  clear: both;
}
.form .formRow:nth-child(even) {
  padding-left: 20px;
}
.form .formRow:nth-child(even) label {
  left: 20px;
}
.form .formRow.isFull {
  clear: both;
  margin-top: 0;
  padding-right: 0 !important;
  width: 100%;
}
.form .formRow input,
.form .formRow textarea {
  width: 100%;
  font-size: 18px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  padding: 15px 0 8px;
}
.form .formRow input.noLabel,
.form .formRow textarea.noLabel {
  padding-top: 0;
}
.form .formRow input:focus + label,
.form .formRow textarea:focus + label {
  transform: translate3d(0, -22px, 0);
  -moz-transform: translate3d(0, -22px, 0);
  -ms-transform: translate3d(0, -22px, 0);
  -webkit-transform: translate3d(0, -22px, 0);
  text-transform: uppercase;
  font-size: 12px;
}
.form .formRow input:focus + label .labelTitle,
.form .formRow textarea:focus + label .labelTitle {
  opacity: 1;
}
.form .formRow select {
  width: 100%;
  font-size: 18px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  padding: 7px 0;
}
.form .formRow input.errorInput {
  border-bottom-color: #e9382b;
}
.form .formRow select.errorInput {
  border-bottom-color: #e9382b;
}
.form .formRow label {
  transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  position: absolute;
  width: 100%;
  top: 16px;
  left: 0;
  font-size: 18px;
  transition: all 0.2s ease-in-out;
}
.form .formRow label span.labelTitle {
  opacity: 0.5;
}
.form .formRow label.selectLabel {
  position: relative;
  top: -9px;
  left: 0;
  transition: none;
  font-size: 12px;
}
.form .formRow label.selectLabel span {
  font-size: 12px;
  text-transform: uppercase;
}
.form .formRow label.animate {
  transform: translate3d(0, -22px, 0);
  -moz-transform: translate3d(0, -22px, 0);
  -ms-transform: translate3d(0, -22px, 0);
  -webkit-transform: translate3d(0, -22px, 0);
  text-transform: uppercase;
  font-size: 12px;
}
.form .formRow label.animate .labelTitle {
  opacity: 1;
}
.form .formRow label.animate .labelTitle.transparentFont {
  opacity: 0.5;
}
.form .formRow input[data-empty=false] + label {
  transform: translate3d(0, -22px, 0);
  -moz-transform: translate3d(0, -22px, 0);
  -ms-transform: translate3d(0, -22px, 0);
  -webkit-transform: translate3d(0, -22px, 0);
  text-transform: uppercase;
  font-size: 12px;
}
.form .formRow .errorInputMsg {
  color: #e9382b;
  font-size: 10px;
  text-transform: none;
  opacity: 1;
}
.form .formRow .errorInputMsg + label {
  transform: translate3d(0, -22px, 0);
  -moz-transform: translate3d(0, -22px, 0);
  -ms-transform: translate3d(0, -22px, 0);
  -webkit-transform: translate3d(0, -22px, 0);
  text-transform: uppercase;
  font-size: 12px;
}
.form .buttons {
  position: relative;
  float: right;
  clear: both;
  margin-top: 35px;
}
.form .buttons button {
  margin-left: 50px;
  text-transform: uppercase;
  font-size: 14px;
}
.form .buttons .btnSuccess {
  border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -webkit-border-radius: 2px;
  box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.05);
  padding: 15px 50px 12px;
  background: #86bc37;
  color: #fff;
}
.form .buttons .btnDanger {
  color: #e9382b;
  border-bottom: 1px solid;
}
.form .buttons .btnInfo {
  color: rgb(128, 128, 128);
  border-bottom: 1px solid;
}
.form .infoIcon {
  position: relative;
  display: inline-block;
  width: 11px;
  height: 11px;
  margin-left: 8px;
  top: -1px;
  background: url("./images/info.png") no-repeat center center/11px 11px;
  cursor: pointer;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .form .infoIcon {
    /* on retina, use image that's scaled by 2 */
    background-image: url("./images/info@2x.png");
    background-size: 11px 11px;
  }
}
.form .editSiteIDLink {
  float: right;
  margin-right: 20px;
}

.twoInputsForm {
  position: relative;
  float: left;
  width: 100%;
}
.twoInputsForm .formRow {
  margin-top: 50px;
  text-align: center;
}
.twoInputsForm .formRow label {
  position: relative;
  display: inline-block;
  width: 100%;
  color: #fff;
}
.twoInputsForm .formRow label span {
  position: relative;
  display: inline-block;
  font-size: 24px;
  text-transform: uppercase;
}
.twoInputsForm .formRow label input {
  position: relative;
  float: left;
  width: 100%;
  margin-top: 20px;
  padding-bottom: 5px;
  font-size: 22px;
  line-height: 32px;
  text-align: center;
  border-bottom: 4px solid rgba(255, 255, 255, 0.5);
  color: #fff;
}
.twoInputsForm .formRow label input[type=text] {
  text-transform: capitalize;
}
.twoInputsForm .formRow label input.passwordInput {
  text-transform: none;
}
.twoInputsForm .formRow.inactive label {
  opacity: 0.5;
}
.twoInputsForm .formRow.active input {
  border-bottom-color: #fff;
}
.twoInputsForm .noticeRow {
  margin-top: 20px;
}
.twoInputsForm .noticeRow a {
  font-size: 14px;
  line-height: 16px;
  text-decoration: underline;
  color: #fff;
  opacity: 0.3;
  text-transform: uppercase;
}
.twoInputsForm .noticeRow a:hover {
  opacity: 1;
}
.twoInputsForm .buttonRow {
  margin-top: 40px;
}
.twoInputsForm button {
  border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -webkit-border-radius: 2px;
  box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.05);
  padding: 15px 65px 12px;
  font-size: 18px;
  line-height: 26px;
  text-transform: uppercase;
  background: #fff;
  color: #00788a;
  opacity: 0.1;
}
.twoInputsForm button:disabled {
  cursor: auto;
}
.twoInputsForm button.activeBtn {
  opacity: 1;
  cursor: pointer;
}
.twoInputsForm button.redBtn {
  color: #e9382b;
}
.twoInputsForm .togglePswd {
  box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -webkit-box-shadow: none;
  position: absolute;
  bottom: 10px;
  right: 0;
  padding: 0;
  background: none;
  opacity: 0.5;
  color: #fff;
  font-size: 14px;
}

input {
  padding: 0;
  border: none;
  background: none;
}

select {
  border: none;
}

option {
  padding: 0;
}

.asCheckbox {
  opacity: 1 !important;
  background: url("./images/tick.png") no-repeat right center/12px 9px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .asCheckbox {
    /* on retina, use image that's scaled by 2 */
    background-image: url("./images/tick@2x.png");
    background-size: 12px 9px;
  }
}

nav {
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 25px;
}
nav ul {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
}
nav li {
  padding-bottom: 25px;
}
nav li a {
  padding: 0;
  font-size: 24px;
  line-height: 36px;
  color: #fff;
  text-transform: uppercase;
  opacity: 0.5;
}
nav li a:hover {
  opacity: 1;
}
nav li a.active {
  opacity: 1;
}
nav #animatedMenu {
  position: relative;
  float: left;
  width: 100%;
  height: 8px;
  border-bottom: 2px solid #fff;
}
nav #animatedMenu #animatedLine {
  position: absolute;
  display: block;
  float: left;
  width: 0;
  left: 0;
  top: 0;
  height: 6px;
  background: #fff;
  transition: all 0.5s ease;
}

.nav {
  position: relative;
  width: 100%;
  text-align: center;
}
.nav li {
  display: inline-block;
}
.nav li a {
  padding: 0 10px;
  font-size: 18px;
  line-height: 26px;
  color: #fff;
  text-transform: uppercase;
  opacity: 0.5;
}
.nav li a:hover {
  opacity: 1;
}
.nav li a.active {
  opacity: 1;
}

.list {
  position: relative;
  float: left;
  width: 100%;
}
.list .singleList {
  margin-bottom: 15px;
}
.list table {
  opacity: 1;
  position: relative;
  width: 100%;
  transition: opacity 0.25s ease;
  border-collapse: collapse;
}
.list table .listRow {
  border-bottom: 5px solid;
  background: #fff;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
}
.list table .listRow td {
  padding: 17px 0 14px;
}
.list table .listRow td:first-child {
  padding-left: 20px;
}
.list table .listRow td:last-child {
  padding-right: 20px;
}
.list table .listRow a {
  float: left;
  text-decoration: underline;
}
.list table .listRow .asLink {
  float: left;
  text-decoration: underline;
  margin-left: 30px;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
}
.list table .listRow .pic {
  float: left;
  width: 20px;
  height: 18px;
  margin-right: 23px;
  background: url("./images/attached-image.png") no-repeat center center/contain;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .list table .listRow .pic {
    /* on retina, use image that's scaled by 2 */
    background-image: url("./images/attached-image@2x.png");
    background-size: 20px 18px;
  }
}
.list table .listRow .check {
  display: inline-block;
  width: 19px;
  height: 15px;
  background: url("./images/big-green-tick.png") no-repeat center center/19px 15px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .list table .listRow .check {
    /* on retina, use image that's scaled by 2 */
    background-image: url("./images/big-green-tick@2x.png");
    background-size: 19px 15px;
  }
}
.list h3 {
  margin-bottom: 7px;
  font-size: 12px;
  line-height: 18px;
  color: #fff;
  text-transform: uppercase;
  opacity: 0.5;
}
.list ul {
  margin-bottom: 15px;
}

.list.is-loading table {
  opacity: 0.75;
}
.list.is-loading table a {
  pointer-events: none;
}

.listNav {
  position: relative;
  float: right;
  margin-top: 13px;
}
.listNav a {
  margin-top: 13px;
  font-size: 16px;
  opacity: 0.2;
  color: #fff;
  text-transform: uppercase;
  cursor: default;
}
.listNav a.active {
  opacity: 1;
  cursor: pointer;
}
.listNav a.prev {
  padding-right: 20px;
  padding-left: 15px;
  border-right: 2px solid #fff;
  background: url("./images/prev.png") no-repeat left top 1px/8px 12px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .listNav a.prev {
    /* on retina, use image that's scaled by 2 */
    background-image: url("./images/prev@2x.png");
    background-size: 8px 12px;
  }
}
.listNav a.next {
  padding-left: 20px;
  padding-right: 15px;
  background: url("./images/next.png") no-repeat right top 1px/8px 12px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .listNav a.next {
    /* on retina, use image that's scaled by 2 */
    background-image: url("./images/next@2x.png");
    background-size: 8px 12px;
  }
}

.filterList {
  position: relative;
  float: left;
  margin-top: 13px;
}
.filterList button {
  display: inline-block;
  font-size: 16px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
}
.filterList button:first-child {
  padding-right: 30px;
  border-right: 2px solid #fff;
}
.filterList button:last-child {
  padding-left: 30px;
}
.filterList button.active {
  color: #fff;
}

#pagination {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  position: relative;
  width: 100%;
  padding-top: 40px;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
}

.pagination-pages {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

.pagination-page {
  height: 44px;
  margin: 5px;
  width: 44px;
}
.pagination-page .pagination-btn:hover {
  background-color: rgba(255, 255, 255, 0.5);
}
.pagination-page .pagination-btn.active {
  border-color: #fff;
}
.pagination-page .pagination-btn.active:hover {
  border-color: #fff;
}

.pagination-btn {
  transition-delay: 0s;
  transition-duration: 0.4s;
  transition-property: background-color, opacity;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  border: 1px solid transparent;
  color: #fff;
  font-size: 18px;
  font-weight: 900;
  height: 100%;
  letter-spacing: 2px;
  min-width: 44px;
  opacity: 0.8;
  padding: 0 8px 0 10px;
  width: auto;
}
.pagination-btn:disabled {
  pointer-events: none;
  opacity: 0.5;
}
.pagination-btn:hover {
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}
.pagination-btn.svg-btn {
  min-width: 44px;
  text-indent: -9999px;
}
.pagination-btn.is-first {
  background: url("./images/first-white.png") no-repeat center top 15px/12px 12px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .pagination-btn.is-first {
    /* on retina, use image that's scaled by 2 */
    background-image: url("./images/first-white@2x.png");
    background-size: 12px 12px;
  }
}
.pagination-btn.is-last {
  background: url("./images/last-white.png") no-repeat center top 15px/12px 12px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .pagination-btn.is-last {
    /* on retina, use image that's scaled by 2 */
    background-image: url("./images/last-white@2x.png");
    background-size: 12px 12px;
  }
}
.pagination-btn.is-prev {
  background: url("./images/prev.png") no-repeat center top 15px/8px 12px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .pagination-btn.is-prev {
    /* on retina, use image that's scaled by 2 */
    background-image: url("./images/prev@2x.png");
    background-size: 8px 12px;
  }
}
.pagination-btn.is-next {
  background: url("./images/next.png") no-repeat center top 15px/8px 12px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .pagination-btn.is-next {
    /* on retina, use image that's scaled by 2 */
    background-image: url("./images/next@2x.png");
    background-size: 8px 12px;
  }
}
.pagination-btn.active {
  background-color: rgba(255, 255, 255, 0.1) !important;
  opacity: 1 !important;
}
.pagination-btn.active:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
  opacity: 1 !important;
}

.pagination-outer-btn {
  height: 44px;
  margin: 5px 0;
  width: auto;
}
.pagination-outer-btn .pagination-btn {
  padding: 0 10px;
  width: auto;
}
.pagination-outer-btn .pagination-btn:first-child {
  margin-right: 10px;
}

.paginationWrapper {
  clear: both;
  padding-top: 10px;
}

.pageSizeLabel {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}
.pageSizeLabel .btn {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  opacity: 0.5;
  text-align: left;
  text-transform: uppercase;
}
.pageSizeLabel span {
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -6px;
}

.pageSizeLabel.active .btn {
  opacity: 1;
}
.pageSizeLabel.active span {
  background-image: url("./images/tick.png");
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: 12px 9px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .pageSizeLabel.active span {
    /* on retina, use image that's scaled by 2 */
    background-image: url("./images/tick@2x.png");
    background-size: 12px 9px;
  }
}

#pagination.not-clickable button {
  pointer-events: none !important;
}

.formRow {
  position: relative;
  float: left;
  width: 100%;
  margin-top: 25px;
}

.errorRow {
  position: relative;
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 12px;
}
.errorRow span {
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
}

.errorRow.mainFormError p {
  margin: 0;
  color: #e9382b;
  text-transform: uppercase;
  text-align: left;
}

.successRow {
  position: relative;
  float: left;
  width: 100%;
  margin-top: 12px;
}
.successRow p {
  margin: 0;
  color: #7ac143 !important;
  text-transform: uppercase;
}

.termsRow {
  color: #fff;
  position: relative;
  float: left;
  width: 100%;
  font-size: 18px;
  line-height: 1.2;
  margin: 25px 0 0;
}
.termsRow input[type=checkbox] {
  font-size: 18px;
  left: 0;
  margin: 0;
  position: absolute;
  width: auto;
}
.termsRow label {
  display: block;
  font-size: 18px;
  margin: 0 auto;
  padding: 20px 0 0 20px;
  position: relative;
  width: 80%;
}
.termsRow label::after {
  clear: both;
  content: "";
}

.stepRow {
  position: relative;
  float: left;
  width: 100%;
  margin-top: 18px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #fff;
}

.filterTooltip {
  border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -webkit-border-radius: 2px;
  box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.05);
  position: absolute;
  min-width: 290px;
  right: 0;
  margin-top: 20px;
  padding: 15px 10px 10px;
  background: #7ac143;
  z-index: 10;
}
.filterTooltip li.hiddenImportant {
  display: none !important;
}
.filterTooltip input[type=text] {
  width: 100%;
  padding: 10px;
  background: #77b247;
  color: #fff;
  font-size: 14px;
}
.filterTooltip input[type=text]::-webkit-input-placeholder {
  color: #fff;
  opacity: 0.5;
}
.filterTooltip input[type=text]::-moz-placeholder {
  color: #fff;
  opacity: 0.5;
}
.filterTooltip input[type=text]:-ms-input-placeholder {
  color: #fff;
  opacity: 0.5;
}
.filterTooltip ul {
  position: relative;
  float: left;
  width: 100%;
  padding: 0 10px;
}
.filterTooltip ul li {
  display: block !important;
  position: relative;
  width: 100% !important;
  text-align: left !important;
  padding: 10px 0;
}
.filterTooltip ul li label {
  display: block;
  width: 100%;
}
.filterTooltip ul li label span {
  color: #fff;
  opacity: 0.5;
}
.filterTooltip ul li label.active span {
  opacity: 1;
}
.filterTooltip ul li input[type=checkbox] {
  display: none;
  float: right;
  margin-top: -4px;
}
.filterTooltip ul li input[type=radio] {
  display: none;
  float: right;
  margin-top: -4px;
}
.filterTooltip ul li input[type=radio] + span {
  width: 20px;
  height: 20px;
  margin-top: -6px;
}
.filterTooltip input[type=checkbox] + span {
  float: right;
  margin-top: -3px;
  margin-right: 0;
  background-color: #7ac143;
}
.filterTooltip input[type=checkbox]:checked + span {
  background-image: url("./images/tick.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 12px 9px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .filterTooltip input[type=checkbox]:checked + span {
    /* on retina, use image that's scaled by 2 */
    background-image: url("./images/tick@2x.png");
    background-size: 12px 9px;
  }
}
.filterTooltip input[type=radio] + span {
  float: right;
  margin-top: -3px;
  margin-right: 0;
  background-color: #7ac143;
}
.filterTooltip input[type=radio]:checked + span {
  background-image: url("./images/tick.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 12px 9px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .filterTooltip input[type=radio]:checked + span {
    /* on retina, use image that's scaled by 2 */
    background-image: url("./images/tick@2x.png");
    background-size: 12px 9px;
  }
}
.filterTooltip::before {
  content: "";
  display: block;
  position: absolute;
  right: 56px;
  bottom: 100%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #7ac143;
}
.filterTooltip::after {
  content: "";
  display: block;
  position: absolute;
  right: 56px;
  bottom: 100%;
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-bottom-color: #7ac143;
}

.actionTooltip {
  display: none;
  position: absolute;
  width: 172px;
  top: 30px;
  right: 0;
  padding: 20px 13px;
  background: #e9382b;
  color: #fff;
  text-align: center;
  z-index: 10;
}
.actionTooltip.visible {
  display: block;
}
.actionTooltip p {
  margin-top: 0;
  font-size: 14px;
  line-height: 20px;
  opacity: 0.5;
  text-transform: uppercase;
}
.actionTooltip button {
  display: inline-block;
  padding: 0 7px;
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
}
.actionTooltip::before {
  content: "";
  display: block;
  position: absolute;
  left: 134px;
  bottom: 100%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #e9382b;
}
.actionTooltip::after {
  content: "";
  display: block;
  position: absolute;
  left: 135px;
  bottom: 100%;
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-bottom-color: #e9382b;
}

.infoTooltip {
  border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -webkit-border-radius: 2px;
  box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.05);
  display: none;
  position: absolute;
  width: 200px;
  bottom: calc(100% + 23px);
  left: 55px;
  padding: 20px 17px;
  background: #7ac143;
  text-align: center;
  z-index: 10;
}
.infoTooltip.visible {
  display: block;
}
.infoTooltip p {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #fff !important;
}
.infoTooltip::before {
  content: "";
  display: block;
  position: absolute;
  left: 12px;
  top: 100%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: #7ac143;
}
.infoTooltip::after {
  content: "";
  display: block;
  position: absolute;
  left: 12px;
  top: 100%;
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-top-color: #7ac143;
}

.autocompleteTooltip {
  border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -webkit-border-radius: 2px;
  box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.05);
  position: absolute;
  min-width: 200px;
  margin-top: 15px;
  padding: 10px;
  background: #ac7ba3;
  z-index: 10;
}
.autocompleteTooltip div {
  color: #fff;
  opacity: 0.5;
  padding: 10px;
  cursor: pointer;
}
.autocompleteTooltip::before {
  content: "";
  display: block;
  position: absolute;
  left: 15px;
  bottom: 100%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #ac7ba3;
}
.autocompleteTooltip::after {
  content: "";
  display: block;
  position: absolute;
  left: 15px;
  bottom: 100%;
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-bottom-color: #ac7ba3;
}

.hiddenUpload {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.uploadLoader {
  display: none;
  background-color: #fff;
  color: #007188;
  font-size: 24px;
  margin-bottom: 10px;
  padding: 10px;
}
.uploadLoader.visible {
  display: block;
}

.uploadResult {
  display: none;
  color: #007188;
}
.uploadResult.visible {
  display: block;
}
.uploadResult .totalErrors {
  color: #fff;
  font-size: 24px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #e9382b;
}
.uploadResult .rowsImported {
  color: #fff;
  font-size: 24px;
  margin-bottom: 10px;
  padding: 10px;
}
.uploadResult .error {
  background-color: #fff;
  border-left: 8px solid #e9382b;
  margin-bottom: 10px;
  padding: 10px;
}
.uploadResult .error .rowData {
  word-wrap: break-word;
}
.uploadResult .error .message {
  margin-top: 10px;
}

@media (min--moz-device-pixel-ratio: 1.3) {
  .toggleBtn {
    background-position-y: 4px;
  }
}
@media (-o-min-device-pixel-ratio: 2.6/2) {
  .toggleBtn {
    background-position-y: 4px;
  }
}
@media (-webkit-min-device-pixel-ratio: 1.3) {
  .toggleBtn {
    background-position-y: 4px;
  }
}
@media (min-device-pixel-ratio: 1.3) {
  .toggleBtn {
    background-position-y: 4px;
  }
}
@media (min-resolution: 1.3dppx) {
  #header #logo {
    background-image: url("./images/wates-logo@2x.png");
    background-size: 90px 25px;
  }
  #header #userInfo li button .downArrow {
    background-image: url("./images/down-arrow@2x.png");
    background-size: 12px 8px;
  }
  #header #userInfo li ul li a {
    background-image: url("./images/arrow-white@2x.png");
    background-size: 20px 13px;
  }
  #footer {
    background-image: url("./images/men@2x.png");
    background-size: 190px 288px;
  }
  #footer #zaangLogo {
    background-image: url("./images/zaang-logo@2x.png");
    background-size: 60px 20px;
  }
  .form .infoIcon {
    background-image: url("./images/info@2x.png");
    background-size: 11px 11px;
  }
  .list table .listRow .pic {
    background-image: url("./images/attached-image@2x.png");
    background-size: 20px 18px;
  }
  .list table .listRow .check {
    background-image: url("./images/big-green-tick@2x.png");
    background-size: 19px 15px;
  }
  .listNav a.prev {
    background-image: url("./images/prev@2x.png");
    background-size: 8px 12px;
  }
  .listNav a.next {
    background-image: url("./images/next@2x.png");
    background-size: 8px 12px;
  }
  .searchBar input.searchInput {
    background-image: url("./images/search@2x.png");
    background-size: 18px 18px;
  }
  .searchBar .cancelSearch {
    background-image: url("./images/x-copy@2x.png");
    background-size: 15px 15px;
  }
  .toggleBtn {
    background-image: url("./images/down-arrow@2x.png");
    background-size: 12px 8px;
    background-position-y: 4px;
  }
  .asCheckbox {
    background-image: url("./images/tick@2x.png");
    background-size: 12px 9px;
  }
  .filterTooltip input[type=checkbox]:checked + span {
    background-image: url("./images/tick@2x.png");
    background-size: 12px 9px;
  }
  .filterTooltip input[type=radio]:checked + span {
    background-image: url("./images/tick@2x.png");
    background-size: 12px 9px;
  }
  #dateRangeList .selectButton {
    background-image: url("./images/down-arrow@2x.png");
    background-size: 12px 8px;
  }
  #dateRangeList ul li button.active {
    background-image: url("./images/tick@2x.png");
    background-size: 14px 10px;
  }
  #dateRangeList ul li button.datePickerButton {
    background-image: url("./images/date-icon@2x.png");
    background-size: 15px 14px;
  }
  .datepicker .doneButton button {
    background-image: url("./images/tick@2x.png");
    background-size: 14px 10px;
  }
  .userCard .closeButton {
    background-image: url("./images/x-users@2x.png");
    background-size: 23px 23px;
  }
  #reportList .viewTd .asLink {
    background-image: url("./images/arrow-blue@2x.png");
    background-size: 20px 14px;
  }
  .reportDetail .closeButton {
    background-image: url("./images/x@2x.png");
    background-size: 23px 23px;
  }
  .reportDetail.reportDownload input[type=checkbox]:checked + span {
    background-image: url("./images/tick@2x.png");
    background-size: 12px 9px;
  }
  .reportDetail.reportDownload .downloadHeader .searchRow input.searchInput {
    background-image: url("./images/search-reports@2x.png");
    background-size: 18px 18px;
  }
  .reportDetail.reportDownload .downloadHeader .searchRow .cancelSearch {
    background-image: url("./images/x@2x.png");
    background-size: 15px 15px;
  }
  .reportDetail.reportDownload .downloadHeader .columnsLabels li .toggleBtn {
    background-image: url("./images/down-arrow-reports@2x.png");
    background-size: 12px 8px;
  }
  .reportDetail.reportDownload .downloadHeader .columnsLabels .filterTooltip input[type=checkbox]:checked + span {
    background-image: url("./images/tick@2x.png");
    background-size: 12px 9px;
  }
  .reportDetail.reportDownload .downloadHeader .columnsLabels .filterTooltip input[type=radio]:checked + span {
    background-image: url("./images/tick@2x.png");
    background-size: 12px 9px;
  }
  .reportCheckboxRow input[type=checkbox]:checked + span {
    background-image: url("./images/tick-blue@2x.png");
    background-size: 12px 9px;
  }
  .siteCard .closeButton {
    background-image: url("./images/x-sites@2x.png");
    background-size: 23px 23px;
  }
  #notFoundBox div {
    background-image: url("./images/shape-940@2x.png");
    background-size: 450px 104px;
  }
  .pagination-btn.is-first {
    background-image: url("./images/first-white@2x.png");
    background-size: 12px 12px;
  }
  .pagination-btn.is-last {
    background-image: url("./images/last-white@2x.png");
    background-size: 12px 12px;
  }
  .pagination-btn.is-prev {
    background-image: url("./images/prev@2x.png");
    background-size: 8px 12px;
  }
  .pagination-btn.is-next {
    background-image: url("./images/next@2x.png");
    background-size: 8px 12px;
  }
  .pageSizeLabel.active span {
    background-image: url("./images/tick@2x.png");
    background-size: 12px 9px;
  }
}

