////////////
// VARIABLES
////////////

// colours
$color_white: #fff;
$color_red: #e9382b;
$color_yellow: #ffb700;
$color_green_success: #86bc37;
$color_green: #7ac143;
$color_green_dark: #77b247;
$color_green_mid: #7ab220;
$color_deep_blue: #00466f;
$color_deep_blue_5: rgba(0, 70, 111, .5);
$color_purple_light: #ac7ba3;
$color_purple: #593372;
$color_purple_5: rgba(89, 51, 114, .5);
$color_light_purple_bg:  lighten($color_purple, 10%);
$color_blue: #00788a;
$color_iris_blue: #007188;
$color_user_blue: #78bedf;
$color_grey: rgb(128, 128, 128);
$color_grey_light: #dbebed;
$color_blue_grey: #f3f4f5;
$color_blue_white: #e7f2f3;

$color_white_1: rgba(255, 255, 255, .1);
$color_white_2: rgba(255, 255, 255, .2);
$color_white_5: rgba(255, 255, 255, .5);

$border_color_option_1: transparent $color_white transparent transparent;
$border_color_option_2: transparent transparent transparent $color_white;

//fonts
// $font_family:                   'Agenda Bd';
$font_family:                   'Arial';

//urls
// $font_0:                        '//cloud.typenetwork.com/licenseWeb/118607/fontfile/eot/?2fdca8f6ce2faa25e665f45ae288926584431286';
// $font_1:                        '//cloud.typenetwork.com/licenseWeb/118607/fontfile/eot/?2fdca8f6ce2faa25e665f45ae288926584431286#iefix';
// $font_2:                        '//cloud.typenetwork.com/licenseWeb/118607/fontfile/woff2/?2fdca8f6ce2faa25e665f45ae288926584431286';
// $font_3:                        '//cloud.typenetwork.com/licenseWeb/118607/fontfile/woff/?2fdca8f6ce2faa25e665f45ae288926584431286';

$image_logo:                    url('./images/wates-logo.png');
$image_logo_x2:                 url('./images/wates-logo@2x.png');
$image_down_arrow:              url('./images/downarrow.png');
$image_down_arrow_x2:           url('./images/downarrow@2x.png');
$image_arrow_white:             url('./images/arrow-white.png');
$image_arrow_white_x2:          url('./images/arrow-white@2x.png');
$image_men:                     url('./images/men.png');
$image_men_x2:                  url('./images/men@2x.png');
$image_zaang:                   url('./images/zaang-logo.png');
$image_zaang_x2:                url('./images/zaang-logo@2x.png');
$image_info:                    url('./images/info.png');
$image_info_x2:                 url('./images/info@2x.png');
$image_attached_image:          url('./images/attached-image.png');
$image_attached_image_x2:       url('./images/attached-image@2x.png');
$image_big_green_tick:          url('./images/big-green-tick.png');
$image_big_green_tick_x2:       url('./images/big-green-tick@2x.png');
$image_prev:                    url('./images/prev.png');
$image_prev_x2:                 url('./images/prev@2x.png');
$image_next:                    url('./images/next.png');
$image_next_x2:                 url('./images/next@2x.png');
$image_search:                  url('./images/search.png');
$image_search_x2:               url('./images/search@2x.png');
$image_x_copy:                  url('./images/x-copy.png');
$image_x_copy_x2:               url('./images/x-copy@2x.png');
$image_down_arrow:              url('./images/down-arrow.png');
$image_down_arrow_x2:           url('./images/down-arrow@2x.png');
$image_tick:                    url('./images/tick.png');
$image_tick_x2:                 url('./images/tick@2x.png');
$image_date_icon:               url('./images/date-icon.png');
$image_date_icon_x2:            url('./images/date-icon@2x.png');
$image_x_user:                  url('./images/x-users.png');
$image_x_user_x2:               url('./images/x-users@2x.png');
$image_drop_down_arrow_user:    url('./images/drop-down-arrow-user.png');
$image_drop_down_arrow_user_x2: url('./images/drop-down-arrow-user@2x.png');
$image_arrow_blue:              url('./images/arrow-blue.png');
$image_arrow_blue_x2:           url('./images/arrow-blue@2x.png');
$image_x:                       url('./images/x.png');
$image_x_x2:                    url('./images/x@2x.png');
$image_tick_blue:               url('./images/tick-blue.png');
$image_tick_blue_x2:            url('./images/tick-blue@2x.png');
$image_search_report:           url('./images/search-reports.png');
$image_search_report_x2:        url('./images/search-reports@2x.png');
$image_down_arrow_reports:      url('./images/down-arrow-reports.png');
$image_down_arrow_reports_x2:   url('./images/down-arrow-reports@2x.png');
$image_x_sites:                 url('./images/x-sites.png');
$image_x_sites_x2:              url('./images/x-sites@2x.png');
$image_shape_940:               url('./images/shape-940.png');
$image_shape_940_x2:            url('./images/shape-940@2x.png');
$image_first_white:             url('./images/first-white.png');
$image_first_white_x2:          url('./images/first-white@2x.png');
$image_last_white:              url('./images/last-white.png');
$image_last_white_x2:           url('./images/last-white@2x.png');

// ANIMATIONS
$easing:                      cubic-bezier(.4, 0, .2, 1);
$animation-speed:             250ms;

// BOX SHADOWS
$btn-box-shadow-0:            0 0 0 0 rgba(0, 0, 0, 0);
$btn-box-shadow-1:            0 4px 0 0 rgba(0, 0, 0, .05);
$btn-box-shadow-2:            0 5px 0 0 rgba(0, 0, 0, .05);
$btn-box-shadow-3:            0 -4px 3px rgba(0, 0, 0, .1);
$btn-box-shadow-4:            0 4px 3px rgba(0, 0, 0, .1);

// DIMENSIONS
$border-radius:	              2px;

// OPACITY
$opacity_5:                   .5;
$opacity_10:                  1;
