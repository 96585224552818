/////////////
// DATE RANGE
/////////////

// VARS

// ELEMENTS
#dateRangeList {
  margin-top: 12px;

  .selectButton {
    @include image-2x($image_down_arrow_x2, 12px, 8px);
    display: inline-block;
    padding-right: 20px;
    font-size: 14px;
    text-transform: uppercase;
    color: $color_white;
    background: $image_down_arrow no-repeat right 1px/12px 8px;
  }

  li {
    position: relative;
  }

  ul {
    @include vendor-prefix(border-radius, $border-radius);
    @include vendor-prefix(box-shadow, $btn-box-shadow-2);
    display: none;
    position: absolute;
    width: 290px;
    margin-top: 20px;
    z-index: 10;
    background: $color_green;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 10px;
      bottom: 100%;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-bottom-color: $color_green;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 10px;
      bottom: 100%;
      width: 0;
      height: 0;
      border: 9px solid transparent;
      border-bottom-color: $color_green;
    }

    &.visible {
      display: block;
    }

    li {
      padding: 10px 15px 8px;
      background: $color_green;

      button {
        width: 100%;
        font-size: 14px;
        text-align: left;
        color: $color_white;
        opacity: $opacity_5;

        &.active {
          @include image-2x($image_tick_x2, 14px, 10px);
          opacity: $opacity_10;
          background: $image_tick no-repeat right center/14px 10px;
        }

        &.datePickerButton {
          @include image-2x($image_date_icon_x2, 15px, 14px);
          opacity: $opacity_10;
          background: $image_date_icon no-repeat right center/15px 14px;
        }
      }

      &:first-child {
        padding-top: 20px;
      }

      &:last-child {
        margin-top: 10px;
        padding: 15px 15px;
        background: $color_green_dark;
      }
    }
  }
}

.dateRangeInput {
  display: inline-block;
  width: 288px;
  padding: 5px 0;
  border-bottom: 1px solid $color_blue_white;
  cursor: pointer;
}

.datepicker {
  display: none;
  position: absolute;
  margin-top: 20px;
  max-width: 280px;
  z-index: 20;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 10px;
    bottom: 100%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: $color_green;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 10px;
    bottom: 100%;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-bottom-color: $color_green;
  }

  &.visible {
    display: block;
  }

  .rdrCalendarWrapper {
    background-color: $color_green !important; // stylelint-disable-line declaration-no-important
    padding: 10px;
  }

  .rdrMonthAndYearWrapper {
    display: flex;
    justify-content: space-between;
    margin: 5px 0 15px;

    select {
      background: none;
      color: $color-white;
      text-transform: uppercase;
    }

    .rdrMonthPicker {
      margin-right: 10px;
    }
  }

  .rdrNextPrevButton {
    display: inline-block;
    margin-top: -2px !important; // stylelint-disable-line declaration-no-important
    background-color: $color_green !important; // stylelint-disable-line declaration-no-important
    width: 18px;
    height: 18px;

    i {
      border-style: solid;
      display: block;
      width: 0;
      height: 0;
      padding: 0;
    }

    &.rdrPprevButton {
      i {
        margin: 0 0 0 1px;
        border-width: 4px 6px 4px 4px;
        border-color: $border_color_option_1 !important; // stylelint-disable-line declaration-no-important
      }
    }

    &.rdrNextButton {
      i {
        margin: 0 0 0 7px;
        border-width: 4px 4px 4px 6px;
        border-color: $border_color_option_2 !important; // stylelint-disable-line declaration-no-important
      }
    }
  }

  .rdrWeekDays {
    display: flex;
  }

  .rdrDays {
    display: flex;
    flex-wrap: wrap;
  }

  .rdrWeekDay,
  .rdrDay {
    align-items: center;
    color: $color_white !important; // stylelint-disable-line declaration-no-important
    display: flex;
    font-size: 14px !important; // stylelint-disable-line declaration-no-important
    height: 37px;
    justify-content: center;
    position: relative;
    width: 37px;

    &.rdrWeekDay {
      align-items: flex-start;
      font-size: 12px !important; // stylelint-disable-line declaration-no-important
      font-weight: 600;
      margin-bottom: 1px;
      text-transform: uppercase;
    }

    .rdrDayNumber {
      z-index: 1;
    }

    &.rdrDayPassive {
      opacity: .2 !important; // stylelint-disable-line declaration-no-important
    }

    .rdrStartEdge,
    .rdrEndEdge,
    .rdrInRange {
      background-color: $color_white !important; // stylelint-disable-line declaration-no-important
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;

      & + .rdrDayNumber {
        color: $color_green !important; // stylelint-disable-line declaration-no-important
      }
    }
  }

  .rdr-Calendar {
    background-color: $color_green !important; // stylelint-disable-line declaration-no-important

    span {
      padding: 2px 0;
    }

    .rdr-Day {
      color: $color_white !important; // stylelint-disable-line declaration-no-important
      font-size: 14px !important; // stylelint-disable-line declaration-no-important

      &.is-passive {
        opacity: .2 !important; // stylelint-disable-line declaration-no-important
      }

      &.is-selected {
        background-color: $color_white !important; // stylelint-disable-line declaration-no-important
        color: $color_green !important; // stylelint-disable-line declaration-no-important
      }

      &.is-inRange {
        background-color: $color_white !important; // stylelint-disable-line declaration-no-important
        color: $color_green !important; // stylelint-disable-line declaration-no-important
      }
    }

    .rdr-MonthAndYear {
      span {
        color: $color_white !important; // stylelint-disable-line declaration-no-important
        text-transform: uppercase;
      }

      button {
        margin-top: -2px !important; // stylelint-disable-line declaration-no-important
        background-color: $color_green !important; // stylelint-disable-line declaration-no-important

        &.prev {
          i { // stylelint-disable-line max-nesting-depth
            border-color: $border_color_option_1 !important; // stylelint-disable-line declaration-no-important
          }
        }

        &.next {
          i { // stylelint-disable-line max-nesting-depth
            border-color: $border_color_option_2 !important; // stylelint-disable-line declaration-no-important
          }
        }
      }
    }

    .rdr-WeekDay {
      color: $color_white !important; // stylelint-disable-line declaration-no-important
      text-transform: uppercase;
    }
  }

  .doneButton {
    width: 100%;
    padding: 20px 21px 17px;
    background: $color_green_mid;

    button {
      @include image-2x($image_tick_x2, 14px, 10px);
      width: 100%;
      font-size: 16px;
      color: $color_white;
      opacity: $opacity_5;
      background: $image_tick no-repeat right center/14px 10px;
      text-align: left;
      cursor: default;

      &.active {
        opacity: $opacity_10;
        cursor: pointer;
      }
    }
  }
}
