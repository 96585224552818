//////////
// REPORTS
//////////

.filterRow {
  padding: 30px 0 0;

  .filterContainer {
    padding: 20px 0 0;

    #dateRangeList {
      margin: 0;
    }

    .columnsLabels {
      display: flex;
      justify-content: space-between;
      position: relative;
      width: 100%;

      .limitedDropdown {
        min-width: 290px;
        max-width: 310px;

        ul {
          max-height: 200px;
          overflow-y: scroll;
          overflow-x: hidden;
          width: 100%;
        }
      }
    }

    .toggleBtn.blueBtn {
      @include image-2x($image_down_arrow_reports_x2, 12px, 8px);
      color: $color_iris_blue;
      background: $image_down_arrow_reports no-repeat right top 1px/12px 8px;
    }
  }

  .filterTooltip {
    label {
      cursor: pointer;
      width: inherit;

      span.label {
        float: inherit;
        margin-left: 4px;
      }
    }
  }

  .dateBtn {
    position: relative;

    .labelAbove {
      bottom: 20px;
      position: absolute;
      width: calc(100% - 25px);
    }

    .label {
      display: inline-block;
    }
  }
}

#reportHeader {
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 30px;

  &.is-hidden {
    margin-bottom: 0;
  }

  .reportHeaderBtns {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
  }

  .searchRow {
    position: relative;
    float: left;
    width: 100%;
    margin-top: 35px;
  }

  .searchBar {
    margin-top: 13px;
  }

  .actionButton {
    background: $color_green;

    &:disabled {
      cursor: auto;
      opacity: .5;
    }
  }

  .filterContainer {
    border-top: 1px solid rgba(255, 255, 255, .2);
    padding: 20px 0 0;
  }

  .columnsLabels {
    &:not(:first-of-type) {
      position: relative;
      float: right;
      width: 373px;
      text-align: right;
    }

    &:first-of-type {
      display: flex;
      justify-content: space-between;
      position: relative;
      width: 100%;
    }

    #dateRangeList {
      margin-top: 0;

      .selectButton {
        background-position-y: 4px;
        white-space: nowrap;
      }

      li {
        &:first-of-type {
          ul { // stylelint-disable-line max-nesting-depth
            flex-direction: column;

            &.visible { // stylelint-disable-line max-nesting-depth
              display: flex;
            }
          }
        }
      }

      .datepicker {
        ul {}
      }
    }

    li {
      display: inline-block;
      // width: 50%;
      font-size: 14px;
      color: $color_white;
      text-transform: uppercase;

      // &:last-child {
      // 	text-align: right;
      // }

      select {
        color: $color_white;
        background: none;
        font-size: 14px;
        text-transform: uppercase;

        option {
          background: $color_deep_blue;
        }
      }
    }

    .limitedDropdown {
      min-width: 290px;
      max-width: 310px;

      ul {
        max-height: 200px;
        overflow-y: scroll;
        overflow-x: hidden;
        width: 100%;
      }
    }
  }

  .filterTooltip {
    min-width: 200px;

    label {
      cursor: pointer;
    }
  }
}

#reportList {
  .listRow {
    color: $color_iris_blue;

    td {
      position: relative;
      padding: 0;

      &:first-child {
        width: 75%;
      }
    }

    .rowBorder {
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      border-left-style: solid;
      border-left-width: 8px;
    }

    .actionTooltip {
      top: 50px;
      right: 25px;
    }
  }

  a {
    position: relative;
    width: 100%;
    padding: 17px 25px 13px;
    color: $color_iris_blue;
    text-decoration: none;
  }

  .asLink {
    float: right;
    margin-left: 0;
    padding-right: 25px;
    color: $color_iris_blue;
  }

  .viewTd {
    .asLink {
      @include image-2x($image_arrow_blue_x2, 20px, 14px);
      float: right;
      margin-left: 0;
      padding-right: 25px;
      color: $color_iris_blue;
      background: $image_arrow_blue no-repeat right calc(50% - 2px)/20px 14px;
    }
  }

  .pic {
    float: right;
  }
}

.reportDetail {
  @include animate(opacity);
  position: relative;
  float: left;
  width: 100%;
  opacity: 1;
  padding: 30px;
  background: $color_white;
  border-left-style: solid;
  border-left-width: 8px;

  &.is-updating {
    pointer-events: none;
    opacity: .85;
  }

  h2 {
    color: $color_iris_blue;
  }

  .closeButton {
    @include image-2x($image_x_x2, 23px, 23px);
    background: $image_x no-repeat center center/23px 23px;
  }

  table {
    position: relative;
    float: left;
    width: 100%;
    margin-top: 25px;

    tr {
      &:nth-child(odd) {
        background-color: $color_blue_grey;
      }

      td {
        padding: 14px 10px 12px;
        color: $color_iris_blue;
        vertical-align: top;

        &:first-child {
          width: 290px;
          border-right: 2px solid $color_white;
          text-transform: uppercase;
        }

        div:not(.formRow) {
          width: 100%;
          padding: 14px 10px 12px;
        }

        div.imgContainer {
          position: relative;
          float: left;
          padding: 0;
          margin-bottom: 10px;
          max-width: 267px;

          img { // stylelint-disable-line max-nesting-depth
            display: block;
            width: 100%;
          }
        }
      }

      td.hazardTd {
        div {
          &:nth-child(even) { // stylelint-disable-line max-nesting-depth
            background-color: $color_white;
          }
        }
      }

      td.categoryTd {
        div {
          &:nth-child(even) { // stylelint-disable-line max-nesting-depth
            background-color: $color_blue_grey;
          }
        }
      }
    }
  }

  .reportLabel {
    label {
      align-items: center;
      cursor: pointer;
      display: inline-flex;
      padding-left: 30px;
      position: relative;
    }

    input[type=checkbox] {
      display: none;

      & + span {
        border: 1px solid $color_iris_blue;
        display: block;
        height: 20px;
        left: 0;
        position: absolute;
        width: 20px;
      }

      &:checked {
        & + span {
          @include image-2x($image_tick_blue_x2, 12px, 9px);
          background-image: $image_tick_blue;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 12px 9px;
        }
      }
    }
  }

  .reportTextarea {
    background-color: transparent;
    border: 0 none;
    border-bottom-color: $color_iris_blue;
  }

  .reportInput {
    border-bottom-color: $color_iris_blue;
  }

  .reportUpdate {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 20px 0;
    width: 100%;

    .actionButton {
      @include animate(opacity);
      background-color: $color_iris_blue;
      opacity: 1;

      &:disabled {
        cursor: auto;
        opacity: .5;
      }
    }
  }
}

.reportDetail.reportDownload {
  border: none;

  label {
    display: inline-block;
  }

  input[type=checkbox] {
    display: none;

    & + span {
      float: right;
      width: 20px;
      height: 20px;
      background-color: $color_green;
    }

    &:checked {
      & + span {
        @include image-2x($image_tick_x2, 12px, 9px);
        background-image: $image_tick;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 12px 9px;
      }
    }
  }

  #search {
    &::-webkit-input-placeholder {
      color: $color_iris_blue;
      opacity: $opacity_5;
    }

    &::-moz-placeholder {
      color: $color_iris_blue;
      opacity: $opacity_5;
    }

    &:-ms-input-placeholder {
      color: $color_iris_blue;
      opacity: $opacity_5;
    }
  }

  .downloadHeader {
    label {
      margin-right: 40px;
      color: $color_iris_blue;
      font-size: 16px;
      text-transform: capitalize;

      span {
        float: left;
        margin-right: 15px;
      }
    }

    #dateRangeList {
      ul {
        margin-top: -20px;
      }
    }

    .datepicker {
      margin-top: -20px;
    }

    .searchRow {
      position: relative;
      float: left;
      width: 100%;
      margin-top: 35px;

      .searchBar {
        width: 580px;
        border-bottom: 2px solid $color_blue_white;
        margin-top: 13px;
      }

      input.searchInput {
        @include image-2x($image_search_x2, 18px, 18px);
        color: $color_iris_blue;
        background: $image_search no-repeat left center/18px 18px;
      }

      .cancelSearch {
        @include image-2x($image_x_x2, 15px, 15px);
        background: $image_x no-repeat center center/15px 15px;
      }

      .actionButton {
        @include animate(opacity);
        background: #7ac143;
        box-shadow: none;
        opacity: 1;

        &:disabled {
          cursor: auto;
          opacity: .5;
        }
      }
    }

    .columnsLabels {
      position: relative;
      float: right;
      width: 307px;
      text-align: right;

      li {
        display: inline-block;
        width: 50%;
        font-size: 14px;
        color: $color_iris_blue;
        text-transform: uppercase;

        &:last-child {
          text-align: right;
        }

        .toggleBtn {
          @include image-2x($image_down_arrow_reports_x2, 12px, 8px);
          color: $color_iris_blue;
          background: $image_down_arrow_reports no-repeat right top 1px/12px 8px;
        }
      }

      .filterTooltip {
        ul {
          max-height: 200px;
          overflow-y: scroll;
          overflow-x: hidden;
        }

        input[type='checkbox'] {
          & + span { // stylelint-disable-line max-nesting-depth
            float: right;
            margin-top: -3px;
            margin-right: 0;
            background-color: $color_green;
          }

          &:checked { // stylelint-disable-line max-nesting-depth
            & + span { // stylelint-disable-line max-nesting-depth
              @include image-2x($image_tick_x2, 12px, 9px);
              background-image: $image_tick;
              background-repeat: no-repeat;
              background-position: center center;
              background-size: 12px 9px;
            }
          }
        }

        input[type='radio'] {
          & + span { // stylelint-disable-line max-nesting-depth
            float: right;
            margin-top: -3px;
            margin-right: 0;
            background-color: $color_green;
          }

          &:checked { // stylelint-disable-line max-nesting-depth
            & + span { // stylelint-disable-line max-nesting-depth
              @include image-2x($image_tick_x2, 12px, 9px);
              background-image: $image_tick;
              background-repeat: no-repeat;
              background-position: center center;
              background-size: 12px 9px;
            }
          }
        }
      }
    }
  }

  #downloadList {
    table {
      margin-top: 50px;
      border-collapse: collapse;

      tr {
        border: none;

        &:nth-child(odd) {
          background-color: $color_blue_white;
        }

        td {
          border: none;

          input[type=checkbox] { // stylelint-disable-line max-nesting-depth
            & + span { // stylelint-disable-line max-nesting-depth
              background-color: $color_grey_light;
            }
          }
        }
      }
    }
  }
}

.reportCheckboxRow {
  label {
    display: inline-block;
    margin-top: 9px;
  }

  input[type='checkbox'] {
    display: none;

    & + span {
      float: left;
      width: 20px;
      height: 20px;
      background-color: $color_blue_white;
    }

    &:checked {
      & + span {
        @include image-2x($image_tick_x2, 12px, 9px);
        background-image: $image_tick;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 12px 9px;
      }
    }
  }
}

.reportStatus {
  color: #fff;
  display: flex;
  float: right;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  margin-bottom: 7px;
  position: relative;
  text-transform: uppercase;
  z-index: 2;

  .reportStatusLabel {
    opacity: .5;
  }

  .reportStatusSeparator {
    padding: 0 5px;
    opacity: .5;
  }

  .reportStatusOption {
    @include animate(opacity);
    cursor: pointer;
    opacity: .5;

    &:hover,
    &.active {
      opacity: 1;
    }
  }
}
