///////
// LIST
///////

.list {
  position: relative;
  float: left;
  width: 100%;

  .singleList {
    margin-bottom: 15px;
  }

  table {
    opacity: $opacity_10;
    position: relative;
    width: 100%;
    transition: opacity .25s ease;
    border-collapse: collapse;

    .listRow {
      border-bottom: 5px solid;
      background: $color_white;
      font-size: 18px;
      line-height: 22px;
      text-transform: uppercase;

      td {
        padding: 17px 0 14px;

        &:first-child {
          padding-left: 20px;
        }

        &:last-child {
          padding-right: 20px;
        }
      }

      a {
        float: left;
        text-decoration: underline;
      }

      .asLink {
        float: left;
        text-decoration: underline;
        margin-left: 30px;
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
      }

      .pic {
        @include image-2x($image_attached_image_x2, 20px, 18px);
        float: left;
        width: 20px;
        height: 18px;
        margin-right: 23px;
        background: $image_attached_image no-repeat center center/contain;
      }

      .check {
        @include image-2x($image_big_green_tick_x2, 19px, 15px);
        display: inline-block;
        width: 19px;
        height: 15px;
        background: $image_big_green_tick no-repeat center center/19px 15px;
      }
    }
  }

  h3 {
    margin-bottom: 7px;
    font-size: 12px;
    line-height: 18px;
    color: $color_white;
    text-transform: uppercase;
    opacity: $opacity_5;
  }

  ul {
    margin-bottom: 15px;
  }
}

.list.is-loading {
  table {
    opacity: .75;

    a {
      pointer-events: none;
    }
  }
}

.listNav {
  position: relative;
  float: right;
  margin-top: 13px;

  a {
    margin-top: 13px;
    font-size: 16px;
    opacity: .2;
    color: $color_white;
    text-transform: uppercase;
    cursor: default;

    &.active {
      opacity: $opacity_10;
      cursor: pointer;
    }

    &.prev {
      @include image-2x($image_prev_x2, 8px, 12px);
      padding-right: 20px;
      padding-left: 15px;
      border-right: 2px solid $color_white;
      background: $image_prev no-repeat left top 1px/8px 12px;
    }

    &.next {
      @include image-2x($image_next_x2, 8px, 12px);
      padding-left: 20px;
      padding-right: 15px;
      background: $image_next no-repeat right top 1px/8px 12px;
    }
  }
}

.filterList {
  position: relative;
  float: left;
  margin-top: 13px;

  button {
    display: inline-block;
    font-size: 16px;
    text-transform: uppercase;
    color: $color_white_5;

    &:first-child {
      padding-right: 30px;
      border-right: 2px solid $color_white;
    }

    &:last-child {
      padding-left: 30px;
    }
  }

  button.active {
    color: $color_white;
  }
}
