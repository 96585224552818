/////////////
// PAGINATION
/////////////

#pagination {
  @include flexbox();
  @include justify-content(space-between);
  @include flex-direction();
  position: relative;
  width: 100%;
  padding-top: 40px;
  font-size: 14px;
  text-transform: uppercase;
  color: $color_white;
}

.pagination-pages {
  @include flexbox();
}

.pagination-page {
  height: 44px;
  margin: 5px;
  width: 44px;

  .pagination-btn {
    &:hover {
      background-color: $color_white_5;
    }
  }

  .pagination-btn.active {
    border-color: $color_white;

    &:hover {
      border-color: $color_white;
    }
  }
}

.pagination-btn {
  @include animate(background-color opacity, .4s, cubic-bezier(.25, .8, .25, 1));
  border: 1px solid transparent;
  color: $color_white;
  font-size: 18px;
  font-weight: 900;
  height: 100%;
  letter-spacing: 2px;
  min-width: 44px;
  opacity: .8;
  padding: 0 8px 0 10px;
  width: auto;

  &:disabled {
    pointer-events: none;
    opacity: $opacity_5;
  }

  &:hover {
    background-color: $color_white_5;
    opacity: $opacity_10;
  }

  &.svg-btn {
    min-width: 44px;
    text-indent: -9999px;
  }

  &.is-first {
    @include image-2x($image_first_white_x2, 12px, 12px);
    background: $image_first_white no-repeat center top 15px/12px 12px;
  }

  &.is-last {
    @include image-2x($image_last_white_x2, 12px, 12px);
    background: $image_last_white no-repeat center top 15px/12px 12px;
  }

  &.is-prev {
    @include image-2x($image_prev_x2, 8px, 12px);
    background: $image_prev no-repeat center top 15px/8px 12px;
  }

  &.is-next {
    @include image-2x($image_next_x2, 8px, 12px);
    background: $image_next no-repeat center top 15px/8px 12px;
  }

  &.active {
    background-color: $color_white_1 !important; // stylelint-disable-line declaration-no-important
    opacity: 1 !important; // stylelint-disable-line declaration-no-important

    &:hover {
      background-color: $color_white_1 !important; // stylelint-disable-line declaration-no-important
      opacity: 1 !important; // stylelint-disable-line declaration-no-important
    }
  }
}

.pagination-outer-btn {
  height: 44px;
  margin: 5px 0;
  width: auto;

  .pagination-btn {
    padding: 0 10px;
    width: auto;

    &:first-child {
      margin-right: 10px;
    }
  }
}

.paginationWrapper {
  clear: both;
  padding-top: 10px;
}

.pageSizeLabel {
  @include flexbox();
  @include justify-content(space-between);

  .btn {
    @include flex(1);
    color: $color_white;
    cursor: pointer;
    font-size: 14px;
    opacity: .5;
    text-align: left;
    text-transform: uppercase;
  }

  span {
    display: block;
    width: 20px;
    height: 20px;
    margin-top: -6px;
  }
}

.pageSizeLabel.active {
  .btn {
    opacity: $opacity_10;
  }

  span {
    @include image-2x($image_tick_x2, 12px, 9px);
    background-image: $image_tick;
    background-repeat: no-repeat;
    background-position: 50% 100%;
    background-size: 12px 9px;
  }
}

#pagination.not-clickable {
  button {
    pointer-events: none !important; // stylelint-disable-line declaration-no-important
  }
}
